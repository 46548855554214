import React, { KeyboardEventHandler, PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { SquadBetPlayer, SquadBetTeam } from "./types"
import _ from "lodash"
import I18n from "i18n-js"
import { unwrapSec } from "./helper"
import axios from "axios"
import Button from "../Button"

const flagImgContext = require.context("@images/flags/", true, /.*/)

interface SquadSectionProps {
  title: string
  teamTitle: string
  teamInfo: string
  subTitle?: string | ReactNode
  subInfo: string
  squadItems: ReactNode[]
  teamItem: ReactNode
  subItem?: ReactNode
}

export const SquadSection: React.FC<SquadSectionProps> = ({title, teamTitle, teamInfo, subTitle, subInfo, squadItems, subItem, teamItem}) => {
  return <div className="squadBet__squadSection">
    <div className="squadBet__squadSection__squadHolder">
      <div className="squadBet__squadSection__squadHolder__inner">
        <div className="squadBet__squadSection__squadHolder__title">
          <h2>{title}</h2>
        </div>
        <div className="squadBet__squadSection__squadHolder__items">
          <SquadPlayerItemsHolder items={squadItems} />
        </div>
      </div>
    </div>
    <div className="squadBet__squadSection__subAndTeamHolder">
      <div className="squadBet__squadSection__subHolder">
        <div className="squadBet__squadSection__subHolder__inner">
          <div className="squadBet__squadSection__subHolder__info">
            {subTitle && <h2>{subTitle}</h2>}
            <p>{subInfo}</p>
          </div>
          {subItem && <div className="squadBet__squadSection__subHolder__item">
            {subItem}
          </div>}
        </div>
      </div>
      <div className="squadBet__squadSection__teamHolder">
        <div className="squadBet__squadSection__teamHolder__inner">
          <div className="squadBet__squadSection__teamHolder__info">
            <h2>{teamTitle}</h2>
            <p>{teamInfo}</p>
          </div>
          <div className="squadBet__squadSection__teamHolder__item">
            {teamItem}
          </div>
        </div>
      </div>
    </div>
  </div>
}

export const SquadPlayerItemsHolder: React.FC<{items: ReactNode[]}> = ({items}) => {
  return <div className="squadBet__playerItemsHolder">
    {_.map(items, (i, index) => {
      return <div key={index} className="squadBet__playerItemsHolder__item">{i}</div>
    })}
  </div>
}

export const PlayerItemInner: React.FC<{player: SquadBetPlayer}> = ({player}) => {
  return <div className="squadBet__playerPickItem">
    <div className={`squadBet__playerPickItem__teamBg livTeam--${player.team_key}`} />
    <img className="squadBet__playerPickItem__playerImg" src={player.imageSrc} />
    {player.countryCode && <img className="squadBet__playerPickItem__flagImg" src={flagImgContext(`./${player.countryCode.toLocaleUpperCase()}.svg`)} />}
  </div>
}

export const TeamItemInner: React.FC<{team: SquadBetTeam}> = ({team}) => {
  return <div className={`squadBet__teamPickItem livTeam--${team.team_key}`} />
}

export const PlusItemInner: React.FC = () => {
  return <div className="squadBet__plusItemInner"><span>{I18n.t("squadbet.action_pick")}</span></div>
}

export const PlayerDummyItemInner: React.FC = () => {
  return <div className="squadBet__playerDummyPickItem" />
}

export const SquadBetCountdown: React.FC<{secondsLeft: number}> = ({secondsLeft}) => {
  const { d, hh, mm } = unwrapSec(secondsLeft)
  return secondsLeft > 0
    ? <div className="squadBet__countdown">
        <div className="squadBet__countdown__label">{I18n.t("squadbet.countdown.countdown")}</div>
        <div className="squadBet__countdown__values">
          <div>
            <strong>{d}</strong>
            <span>{I18n.t("squadbet.countdown.days")}</span>
          </div>
          <div>
            <strong>{hh}</strong>
            <span>{I18n.t("squadbet.countdown.hours")}</span>
          </div>
          <div>
            <strong>{mm}</strong>
            <span>{I18n.t("squadbet.countdown.minutes")}</span>
          </div>
        </div>
      </div>
    : <div className="squadBet__countdown__alreadyStarted">
        <span  className="squadBet__countdown__alreadyStarted__note">{I18n.t("squadbet.countdown.event_started")}</span>
      </div>
}

export const EmptyItemInner: React.FC<{emptyReason: "nothingPicked" | "censored"}> = ({emptyReason}) => {
  return <div className="squadBet__emptyItemInner">
    <span>{emptyReason === "nothingPicked" ? "-" : "?"}</span>
  </div>
}

export interface PickItemHolderProps {
  title?: string
  placeholderTitle?: string
  score?: number
  greyout?: Boolean
  aboveTitle?: string
  above?: ReactNode
  below?: ReactNode
  stats?: (string[])[]
  actionButton?: {text: string, action(): void}
}
export const PickItemHolder: React.FC<PropsWithChildren<PickItemHolderProps>> = ({above, aboveTitle, actionButton, children, greyout, placeholderTitle, stats, title, below}) => {
  return <div className={`squadBet__pickItemHolder${greyout ? " squadBet__pickItemHolder--greyout" : ""}`}>
    {above && <div className="squadBet__pickItemHolder__above">{above}</div>}
    <div className="squadBet__pickItemHolder__pick">{children}</div>
    <div className="squadBet__pickItemHolder__textStuff">
      {aboveTitle && <div className="squadBet__pickItemHolder__aboveTitle">{aboveTitle}</div>}
      {placeholderTitle && <div className="squadBet__pickItemHolder__placeholderTitle">{placeholderTitle}</div>}
      {title && <div className="squadBet__pickItemHolder__title">{title}</div>}
      {stats && <div className="squadBet__pickItemHolder__stats">
        {_.map(stats, (s, index) => {
          return <div key={index}><span>{s[0]}</span><strong>{s[1]}</strong></div>
        })}</div>}
      {actionButton && <div className="squadBet__pickItemHolder__actionButton">
        <a onClick={() => actionButton.action()}>{actionButton.text}</a></div>}
    </div>
    {below && <div className="squadBet__pickItemHolder__below">{below}</div>}
  </div>
}

interface PickItemProps {
  actionable: Boolean
  onClick?(): void
  withChangeIcon?: Boolean
}
export const PickItem: React.FC<PropsWithChildren<PickItemProps>> = ({actionable, onClick, withChangeIcon, children}) => {
  const Inner = <>
      <span className="squadBet__pickItem__inner">{children}</span>
      {withChangeIcon && <span className="squadBet__pickItem__changeIcon" />}
    </>

  return actionable
    ? <a className="squadBet__pickItem" onClick={() => onClick?.()}>{Inner}</a>
    : <span className="squadBet__pickItem">{Inner}</span>
}


// ---- TIEBREAKER QUESTION ----- //

const postAnswerIfValid = (roundId: number, answerString: string, authenticity_token: string) => {
  return new Promise((resolve, reject) => {
    if (/^-?[0-9]+$/.test(answerString)) {
      axios.post("/tiebreaker/", {
        authenticity_token,
        round_id: roundId,
        answer_value: answerString
      }).then(resolve).catch(reject)
    } else {
      reject(I18n.t("squadbet.tiebreaker_question.no_valid_number"))
    }
  })
}

interface InputProps {
  inputRef: React.RefObject<HTMLInputElement>
  answerValue: string
  setAnswerValue: React.Dispatch<React.SetStateAction<string>>
  sendAnswer: () => void
}

const TiebreakerInput: React.FC<InputProps> = ({ inputRef, answerValue, setAnswerValue, sendAnswer }) => {

  const onKeyDown: KeyboardEventHandler = useCallback((e) => {
    if (e.key === "Enter") {
      sendAnswer()
    }
  }, [sendAnswer])

  const increaseAnswer = useCallback(() => {
    setAnswerValue((current) => (parseInt(current) + 1).toString())
  }, [])

  const decreaseAnswer = useCallback(() => {
    setAnswerValue((current) => (parseInt(current) - 1).toString())
  }, [])

  return (
    <div className="tiebreakerQuestion__inputWrapper">
      <div className="tiebreakerQuestion__numberInputHolder">
        <input
          type="number"
          ref={inputRef}
          className="input input--higher"
          onKeyDown={onKeyDown}
          value={answerValue}
          onChange={(e) => setAnswerValue(e.target.value)}
          placeholder={I18n.t("squadbet.tiebreaker_question.answer_placeholder")}
          />
          <div className="numberInput__spinnerButtons">
            <div
              className="numberInput__spinnerButton numberInput__spinnerButton--up"
              onClick={increaseAnswer}
            />
            <div
              className="numberInput__spinnerButton numberInput__spinnerButton--down"
              onClick={decreaseAnswer}
            />
          </div>
      </div>
      <Button primary noMinWidthOnMobile onClick={sendAnswer} title={I18n.t("squadbet.tiebreaker_question.btn_save")} />
    </div>
  )
}

type FeedbackMessage = ErrorMessage | SuccessMessage | NullMessage
type NullMessage = { type: "null", message: ""}
type ErrorMessage = { type: "error", message: string }
type SuccessMessage = { type: "success", message: string }

const NullMessage = (): NullMessage => ({ type: "null", message: "" })

const FeedbackMessageComponent: React.FC<{feedbackMsg: FeedbackMessage}> = ({feedbackMsg}) => {

  const classes = useMemo(() => [
    "tiebreakerQuestion__feedbackMessage",
    feedbackMsg ? `tiebreakerQuestion__feedbackMessage--${feedbackMsg.type}` : "",
  ].join(" "), [feedbackMsg])

  return (
    <div className={classes}>
      {feedbackMsg.message}&nbsp;
    </div>
  )
}

interface TiebreakerProps {
  question: string
  answer?: string | number
  round: number
  authToken: string
  canEdit: boolean
}

const DEFAULT_PICKER_VALUE = "-30"

export const TiebreakerQuestion: React.FC<TiebreakerProps> = ({ round, question, answer, authToken, canEdit }) => {

  const [feedbackMsg, setFeedbackMsg] = useState<FeedbackMessage>(NullMessage())
  const [answerValue, setAnswerValue] = useState<string>(answer?.toString() || DEFAULT_PICKER_VALUE);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [timer])

  const sendAnswer = useCallback(() => {
    postAnswerIfValid(round, answerValue, authToken)
      .then(() => {
        setFeedbackMsg({ type: "success", message: I18n.t("squadbet.tiebreaker_question.success")})
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(() => {
          setFeedbackMsg(NullMessage())
        }, 5000))
      })
      .catch((err) => {
        inputRef.current?.focus()
        inputRef.current?.select()
        if (err.response && err.response.data && err.response.data.message) {
          setFeedbackMsg({type: "error", message: err.response.data.message})
        } else {
          setFeedbackMsg({type: "error", message: err.toString()})
        }
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(() => {
          setFeedbackMsg(NullMessage())
        }, 5000))
      })
  }, [answerValue, inputRef.current])

  return (
    <div className="tiebreakerQuestion__wrapper">
      <div className="tiebreakerQuestion__header">
        <div className="tiebreakerQuestion__headerMeta">
          { question }
        </div>
        <div className="tiebreakerQuestion__headerIcon" />
      </div>
      <div className="tiebreakerQuestion__body">
        {canEdit
          ? <>
              <TiebreakerInput
                inputRef={inputRef}
                answerValue={answerValue}
                setAnswerValue={setAnswerValue}
                sendAnswer={sendAnswer}
              />
              <FeedbackMessageComponent feedbackMsg={feedbackMsg} />
            </>
          : <div className="tiebreakerQuestion__resultWrapper">
              {answer
                ? <>
                    <div className="tiebreakerQuestion__resultLabel">{I18n.t("squadbet.tiebreaker_question.result.label")}</div>
                    <div className="tiebreakerQuestion__result">{answer}</div>
                  </>
                : <div className="tiebreakerQuestion__resultLabel">{I18n.t("squadbet.tiebreaker_question.result.not_given")}</div>
              }
            </div>
        }
      </div>
    </div>
  )
}
