import _ from "lodash"
import { SquadBetPlayer, SquadBetTeam, TSquadBet } from "./types"

export interface SquadBetPlayerSlot {
  player: SquadBetPlayer,
  isSub?: Boolean
  score?: number
  scoreCounts?: Boolean
  automaticallySwappedIn?: Boolean
}

export interface SquadBetTeamSlot {
  team: SquadBetTeam
  score?: number
}

interface ISquadSlots {
  substituteSwappedIn: Boolean
  squadSlots: (SquadBetPlayerSlot | null)[]
  teamSlot: SquadBetTeamSlot | null
  benchSlot: SquadBetPlayerSlot | null
  substitutePlayer: SquadBetPlayer | null
  madeAnyPicks: Boolean
  allPickedPlayers: () => SquadBetPlayer[]
  amountPickedPlayersPerTeam(team_key: string | undefined, dontConsiderThisPick?: SquadBetPlayer): number
  amountPickedCaptains(dontConsiderThisPick?: SquadBetPlayer): number
}

const findPlayer = (bet: TSquadBet, playerId: any) => {
  if (!isValidPick(playerId)) { return null }
  return _.find(bet.players || [], (p) => p.id === playerId) || null
}

const findTeam = (bet: TSquadBet, teamId: any) => {
  if (!isValidPick(teamId)) { return null }
  return _.find(bet.teams, (t) => t.id === teamId) || null
}

const isValidPick = (pick: any) => {
  return (_.isNumber(pick) || _.isString(pick)) && pick !== 0 && pick !== -1 && pick !== ""
}

export const SQUAD_PLAYER_INDEXES = [0, 1, 2, 3]
export const TEAM_INDEX = 4
export const BENCH_INDEX = 5
export const SUB_PLAYER_INDEX = 6

export const getSquadSlots = (bet: TSquadBet): ISquadSlots => {
  const squadPlayers = _.map(SQUAD_PLAYER_INDEXES, (sIndex) => {
    return findPlayer(bet, (bet.picks || [])[sIndex])
  })
  const team = findTeam(bet, (bet.picks || [])[TEAM_INDEX])
  const benchPlayer = findPlayer(bet, (bet.picks || [])[BENCH_INDEX])
  const subPlayer = findPlayer(bet, (bet.picks || [])[SUB_PLAYER_INDEX])
  const numPickedPlayersPerTeamKey: {[team_key: string]: number} = {}
  let numPickedCaptains = 0
  _.each(_.compact([...squadPlayers, benchPlayer]), (p) => {
    if (p.captain) { numPickedCaptains += 1 }
    if (p.team_key) { numPickedPlayersPerTeamKey[p.team_key] = (numPickedPlayersPerTeamKey[p.team_key] || 0) + 1 }
  })
  return {
    substituteSwappedIn: subPlayer ? _.some(squadPlayers, (p) => { return p ? (p as SquadBetPlayer).id === subPlayer.id : false}) : false,
    squadSlots: _.map(squadPlayers, (s, index) => {
      if (_.isNull(s)) { return null }
      return {
        player: s,
        isSub: (s && subPlayer && s.id === subPlayer.id) || false,
        score: _.isNumber(bet.scoring_detail?.[index]?.score) ? bet.scoring_detail?.[index]?.score : undefined,
        scoreCounts: bet.scoring_detail?.[index]?.counted || false,
        automaticallySwappedIn: bet.scoring_detail?.[index]?.automatically_swapped_in || undefined
      }
    }),
    teamSlot: team && {
      team: team,
      score: _.isNumber(bet.scoring_detail?.[TEAM_INDEX]?.score) ? bet.scoring_detail?.[TEAM_INDEX]?.score : undefined,
    },
    allPickedPlayers: () => {
      return _.compact([...squadPlayers, benchPlayer])
    },
    madeAnyPicks: _.compact([...squadPlayers, benchPlayer, team]).length > 0,
    benchSlot: benchPlayer
      ? {
        player: benchPlayer,
        isSub: (benchPlayer && subPlayer && benchPlayer.id === subPlayer.id) || false,
        score: _.isNumber(bet.scoring_detail?.[BENCH_INDEX]?.score) ? bet.scoring_detail?.[BENCH_INDEX]?.score : undefined,
        scoreCounts: false
      }
      : null,
    substitutePlayer: subPlayer,
    amountPickedPlayersPerTeam(team_key, dontConsiderThisPick) {
      if (!team_key) { return 0 } // 0 when doesn't belong to team
      return Math.max(0, 
        (numPickedPlayersPerTeamKey[team_key] || 0) -
        (dontConsiderThisPick && dontConsiderThisPick.team_key ? (dontConsiderThisPick.team_key === team_key ? 1 : 0) : 0))
    },
    amountPickedCaptains(dontConsiderThisPick) {
      return Math.max(0, 
        numPickedCaptains -
        (dontConsiderThisPick ? (dontConsiderThisPick.captain ? 1 : 0) : 0))
    },
  }
}

const minTwoDigits = (num: number) => {
  return num < 10 ? `0${num}` : `${num}`
}

const SEC_PER_M = 60
const SEC_PER_H = SEC_PER_M * 60
const SEC_PER_D = SEC_PER_H * 24

export const unwrapSec = (sec: number) => {
  let secLeft = sec
  const d = Math.floor(secLeft / SEC_PER_D)
  secLeft -= d * SEC_PER_D
  const h = Math.floor(secLeft / SEC_PER_H)
  secLeft -= h * SEC_PER_H
  const m = Math.floor(secLeft / SEC_PER_M)
  secLeft -= m * SEC_PER_M
  return {
    d: `${d}`,
    hh: minTwoDigits(h),
    mm: minTwoDigits(m),
    ss: minTwoDigits(secLeft)
  }
}

export const sortPlayers = (p1: SquadBetPlayer, p2: SquadBetPlayer): number => {
  if (p1.team_key !== p2.team_key) {
    if (!p1.team_key) { return 1 }
    if (!p2.team_key) { return -1 }
    return p1.team_key.localeCompare(p2.team_key)
  } else if (p1.captain && !p2.captain) {
    return -1
  } else if (p2.captain && !p1.captain) {
    return 1
  } else {
    return p1.name.localeCompare(p2.name)
  }
}

export type SquadSlots = ReturnType<typeof getSquadSlots>
