import React, { useCallback, useState } from "react"

interface Props {
  symbol: string
  autocomplete: string
  autofocus: boolean
  classes: string
}

const PasswordFieldWithToggle: React.FC<Props> = ({ symbol, autocomplete, autofocus, classes }) => {
  const [type, setType] = useState("password")
  const toggleType = useCallback(() => {
    setType((currentType) => (currentType === "password") ? "text" : "password")
  }, [])
  const iconClass = [
    "registration__passwordVisibleToggle",
    `registration__passwordToggleIcon--${type}`,
  ].join(" ")
  return <div className="registration__passwordFieldWithToggle">
    <input
      autoComplete={autocomplete}
      autoFocus={autofocus}
      className={classes}
      type={type}
      name={`account[${symbol}]`}
      id={`account_${symbol}`}
    />
    <div className={iconClass} onClick={toggleType} />
  </div>
}

export default PasswordFieldWithToggle