import React, { useState, useEffect, useContext } from 'react'

import BetContext from './context/bet'
import betStatusPresenter from './presenter/bet_status'

const SHOW_FOR_SEC = 2
let __saveResultId = 0

const DisplaySaveResult = ({ saveResult, classPrefix }) => {
  const [ currentlyDisplayed, setCurrentlyDisplayed ] = useState({})
  const [ hide, setHide ] = useState(true)

  useEffect(() => { // --------- receiving new message ---------
    if (saveResult && (saveResult.id !== currentlyDisplayed?.id)) {
      setHide(false)
      setCurrentlyDisplayed(saveResult)
    }
  }, [saveResult?.id])

  useEffect(() => { // --------- Hide message after some sec ---------
    const timeout = window.setTimeout(() => {
      setHide(true)
    }, Math.floor(SHOW_FOR_SEC * 1000))
    return () => { // cleanup
      window.clearTimeout(timeout)
    }
  }, [ currentlyDisplayed?.id ]) // only run when new message

  const classNames = [
    `${classPrefix}__saveResult`,
    currentlyDisplayed.isError ? `${classPrefix}__saveResult--error` : `${classPrefix}__saveResult--ok`,
    hide ? `${classPrefix}__saveResult--hide` : `${classPrefix}__saveResult--show`
  ]

  return <div className={classNames.join(' ')}>
    {currentlyDisplayed.msg &&
      <span>{currentlyDisplayed.msg}</span>
    }
  </div>
}

export const createOkResult = (msg) => { return { id: __saveResultId++, isError: false, msg: msg } }
export const createErrorResult = (errorMsg) => { return { id: __saveResultId++, isError: true, msg: errorMsg } }

export default DisplaySaveResult
