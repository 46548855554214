import {chunk, compact, filter, inRange, includes, isEmpty, map, range, some, sum} from "lodash"
import {ChampionshipBetPick, ChampionshipBetTeam, PickValue, TChampionshipBet, TeamId} from "./types"

// indies:[ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9,10,11,12,13,14,15,16,17,18,19,20,21]
// usage :[w1,w1,w1,w1,w1,w2,w2,w2,w2,r3,r3,r3,r3,r3,r3,r3,r3,r3,r3,r3,r3,r3]
// When indicies change, adjust the following files accordingly:
// betty/use_cases/ranking/round_summary.rb
// betty/lib/betting/services/score_helper.rb
// betty_web/app/helpers/bet_helper.rb
// betty_web/app/views/sandbox/sandbox/championshipbet.html.haml


export const NUMBER_OF_RANKS = 13
const ROUND1_NUMBER_OF_TEAMS = 10
const ROUND1_NUMBER_OF_WINNER_PICKS = (ROUND1_NUMBER_OF_TEAMS / 2)

const ROUND2_NUMBER_OF_PREQUALIFIED_TEAMS = 3
const ROUND2_NUMBER_OF_WINNER_PICKS = 4
const ROUND2_OFFSET_WINNERS = ROUND1_NUMBER_OF_WINNER_PICKS

const ROUND3_OFFSET = ROUND2_OFFSET_WINNERS + ROUND2_NUMBER_OF_WINNER_PICKS

export const TOTAL_PICKS = ROUND1_NUMBER_OF_WINNER_PICKS + ROUND2_NUMBER_OF_WINNER_PICKS + NUMBER_OF_RANKS

export const pickIsSet = (pick: ChampionshipBetPick) => !!pick && pick !== "0" && pick !== 0

export const getRound1 = (
    picks: ChampionshipBetPick[] | undefined,
    teams: (ChampionshipBetTeam | null)[],
    points: PickValue[],
    event_state: TChampionshipBet["event_state"],
) => {
    return {
        pairings: chunk(teams.slice(0, ROUND1_NUMBER_OF_TEAMS), 2),
        pickedPercent: Math.floor(filter(picks?.slice(0, ROUND2_OFFSET_WINNERS), pickIsSet).length / ROUND1_NUMBER_OF_WINNER_PICKS * 100),
        isOpen: event_state === "open",
        getPick: (index: number) => ({pickId: picks?.[index], points: points[index]}),
        resetIndices: range(ROUND2_OFFSET_WINNERS, ROUND3_OFFSET),
    }
}

const round1PickPercent = (picks: ChampionshipBetPick[] | undefined) => {
    return Math.floor(filter(picks?.slice(0, ROUND1_NUMBER_OF_WINNER_PICKS), pickIsSet).length / ROUND1_NUMBER_OF_WINNER_PICKS * 100)
}

export const getRound2 = (
    picks: ChampionshipBetPick[] | undefined,
    teams: (ChampionshipBetTeam | null)[],
    points: PickValue[],
    event_state: TChampionshipBet["event_state"],
) => {
    const options =
        compact(
            teams.slice(ROUND1_NUMBER_OF_TEAMS, ROUND1_NUMBER_OF_TEAMS + ROUND2_NUMBER_OF_PREQUALIFIED_TEAMS).concat(
                    map(picks?.slice(0, ROUND2_OFFSET_WINNERS), (pick) => teams.find((team) => team?.id === pick))
                )
        )
    const pickIndices = range(ROUND2_OFFSET_WINNERS, ROUND3_OFFSET)
    return {
        options,
        pickedPercent: round2PickPercent(picks),
        isOpen: event_state === "open",
        getPick: (index: number) => ({pickId: picks?.[index], points: points[index]}),
        isPicked: (pickId: number) => filter(picks?.slice(ROUND2_OFFSET_WINNERS, ROUND3_OFFSET),  (pick: ChampionshipBetPick) => pick == pickId).length > 0,
        pickIndices,
        resetIndices: range(ROUND3_OFFSET, ROUND3_OFFSET + NUMBER_OF_RANKS),
        currentIndex: Math.min(filter(picks?.slice(ROUND2_OFFSET_WINNERS, ROUND3_OFFSET), pickIsSet).length, (ROUND2_NUMBER_OF_WINNER_PICKS - 1))
    }
}

const round2PickPercent = (picks: ChampionshipBetPick[] | undefined) => {
    return Math.floor(filter(picks?.slice(ROUND2_OFFSET_WINNERS, ROUND3_OFFSET), pickIsSet).length / ROUND2_NUMBER_OF_WINNER_PICKS * 100)
}


const constructRound3OptionsFunction = (teams: (ChampionshipBetTeam | null)[], picks: ChampionshipBetPick[] | undefined) => {
    const round1Pairings = chunk(teams.slice(0, ROUND1_NUMBER_OF_TEAMS), 2)
    const round1WinnerPicks = picks?.slice(0, ROUND2_OFFSET_WINNERS)
    const round1Losers = compact(round1Pairings.map(([left, right], index) => {
        const pick = round1WinnerPicks?.[index]
        if (left && right && pickIsSet(pick)) {
            return left.id === pick ? right : left
        }
        return undefined
    }))
    const round2Teams = teams.filter(
        (team) => {
            return round1Losers.filter(
                (loser_team) => {
                    return (loser_team.id == team.id)
                }).length == 0
        }
    )
    const round2WinnerPicks = picks?.slice(ROUND2_OFFSET_WINNERS, ROUND3_OFFSET)

    const [round2Winners, round2Losers] = round2Teams.reduce(([winners, losers],check_team, index) => {
        const newWinners = [...winners]
        const newLosers = [...losers]
        if ( round2WinnerPicks.filter((teamId)=>{ return (check_team.id == teamId)}).length > 0){
            newWinners.push(check_team)
        }else  {
            newLosers.push(check_team)
        }

        return [newWinners, newLosers]
    }, [[], []] as ChampionshipBetTeam[][])

    const getOptions = (index: number) => {
        if (inRange(index, 0, 4)) {
            return round2Winners
        } else if (inRange(index, 4, 8)) {
            return round2Losers
        } else {
            return round1Losers
        }
    }
    return getOptions
}

export const getRound3 = (
    picks: ChampionshipBetPick[] | undefined,
    teams: (ChampionshipBetTeam | null)[],
    points: PickValue[],
    event_state: TChampionshipBet["event_state"],
) => {
    const pickIndices = range(ROUND3_OFFSET, ROUND3_OFFSET + NUMBER_OF_RANKS)
    const currentPick = (index: number) => picks?.[pickIndices[index]]
    const findTeam = (index: number) => teams.find((team) => currentPick(index) === team?.id)
    const round3Points = points.slice(ROUND3_OFFSET)
    const pointsSum: PickValue = some(round3Points, (point) => !!point || point === 0) ? sum(round3Points) : undefined
    const getOptions = constructRound3OptionsFunction(teams, picks)

    return {
        getOptions,
        ranking: picks?.slice(ROUND3_OFFSET, ROUND3_OFFSET + NUMBER_OF_RANKS),
        ranks: range(0, NUMBER_OF_RANKS),
        pickedPercent: round3PickPercent(picks),
        pickIndices,
        currentPick,
        findTeam,
        isCorrect: (index: number) => points?.[index + ROUND3_OFFSET] === -1,
        isOpen: event_state === "open",
        points: pointsSum,
    }
}

const round3PickPercent = (picks: ChampionshipBetPick[] | undefined) => {
    return Math.floor(filter(picks?.slice(ROUND3_OFFSET), pickIsSet).length / NUMBER_OF_RANKS * 100)
}

export const buildPicks = (item: ChampionshipBetTeam, prev: ChampionshipBetPick[], pickIndices: number[], pickIndex: number, resetIndices?: number[]) => {
    const swapBuffer = prev[pickIndex]
    const swapIndex = pickIndices.find((pi) => {
        return prev[pi] === item?.id
    })
    const newPicks = prev.concat()
    newPicks[pickIndex] = item?.id || undefined
    if (swapIndex !== undefined) {
        newPicks[swapIndex] = swapBuffer
    }
    if (resetIndices) {
        for (let resetIndex of resetIndices) {
            if (prev[resetIndex] === item.id) {
                newPicks[resetIndex] = undefined
            }
            if ((swapIndex || swapIndex === 0) && prev[resetIndex] === prev[swapIndex]) {
                newPicks[resetIndex] = undefined
            }
        }
    }
    return newPicks
}

export const getOverview = (picks: ChampionshipBetPick[] | undefined) => {
    return {
        pickedPercentRound1: round1PickPercent(picks),
        pickedPercentRound2: round2PickPercent(picks),
        pickedPercentRound3: round3PickPercent(picks),
    }
}
