import I18n from "i18n-js"
import React, { PropsWithChildren } from "react"
import useResize from "use-resize"

type PopupProps = {
  isOpen: boolean
  closePopup: () => void
}

const Popup: React.FC<PropsWithChildren<PopupProps>> = ({ isOpen, closePopup, children }) => {

  const {height: windowHeight} = useResize()

  const maxPickerHeight = Math.floor(windowHeight * .8)

  return <>
    {isOpen && <>
      <div className="squadBet__picker__backdrop" />
      <div className="squadBet__picker__holder" onClick={closePopup}>
        <div className="squadBet__picker__positioner" onClick={(evt) => evt.stopPropagation()}>
          <div className="squadBet__picker">
            <a className="squadBet__picker__close" onClick={closePopup}>{I18n.t("squadbet.action_close_picker")}</a>
            <div className="squadBet__picker__inner" style={{maxHeight: `${maxPickerHeight}px`}}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </>}
  </>
}

export default Popup
