import React, { PropsWithChildren, createContext, useContext, useMemo, useState } from "react"
import { times } from "lodash"

import LegacyBetContext from "@root/bet_utils/context/bet"
import savePicks from "@root/bet_utils/save_picks"
import { createErrorResult, createOkResult } from "@root/bet_utils/DisplaySaveResult"
import { addSecondsLeftToBet } from "@root/bet_utils/use_deadline_countdown"

import { ChampionshipBetPick, TChampionshipBet, WithSecondsLeft } from "./types"
import { getRound1, getRound2, getRound3, getOverview, TOTAL_PICKS } from "./helper"
import { addPolledScoringToBet } from "./pollBetScoring"

const _ChampionshipBetContext = createContext<{
  bet: TChampionshipBet
  changePicks(buildNewPicks: (oldPicks: ChampionshipBetPick[]) =>  ChampionshipBetPick[]): void
  displaySaveResult?: any,
  authToken: string
}>({} as any)

const filledPicksArray = (picks: any[]) => {
  return times(TOTAL_PICKS, (index) => picks?.[index] || null)
}

export const WithChampionshipBet: React.FC<PropsWithChildren<{initialBet: TChampionshipBet, authToken: string}>> = ({ children, initialBet, authToken }) => {
  const [ picks, setPicks ] = useState(initialBet.picks)

  const bet: TChampionshipBet = {
    ...initialBet,
    picks,
  }
  addSecondsLeftToBet(bet)
  addPolledScoringToBet(bet as WithSecondsLeft<TChampionshipBet>, authToken, 60 * 2, setPicks)

  const [ displaySaveResult, setDisplaySaveResult ] = useState<any>()

  const changePicks = (buildNewPicks: (oldPicks: ChampionshipBetPick[]) =>  ChampionshipBetPick[]) => {
    const newPicks = buildNewPicks(picks?.slice(0) || [])
    setPicks(newPicks) // force component update
    savePicks(bet, authToken, filledPicksArray(newPicks),
      /* OK    */ (msg: string) => { setDisplaySaveResult(createOkResult(msg)) },
      /* ERROR */ (errorMsg: string) => { setDisplaySaveResult(createErrorResult(errorMsg)) })
  }

  return <LegacyBetContext.Provider value={bet}>
    <_ChampionshipBetContext.Provider value={{
      bet: bet as any,
      changePicks,
      displaySaveResult,
      authToken
    }}>
      {children}
    </_ChampionshipBetContext.Provider>
  </LegacyBetContext.Provider>
}

export const useChampionshipBet = () => {
  return useContext(_ChampionshipBetContext)
}

export const useRound1 = () => {
  const { bet: { picks, teams, points, event_state } } = useChampionshipBet()
  const round1 = useMemo(() => {
    return getRound1(picks, teams, points, event_state)
  }, [picks, teams, points, event_state])
  return round1
}

export const useRound2 = () => {
  const { bet: { picks, teams, points, event_state } } = useChampionshipBet()
  const round2 = useMemo(() => {
    return getRound2(picks, teams, points, event_state)
  }, [picks, teams, points, event_state])
  return round2
}

export const useRound3 = () => {
  const { bet: { picks, teams, points, event_state } } = useChampionshipBet()
  const round3 = useMemo(() => {
    return getRound3(picks, teams, points, event_state)
  }, [picks, teams, points, event_state])
  return round3
}

export const useOverview = () => {
  const { bet: { picks } } = useChampionshipBet()
  const overview = useMemo(() => {
    return getOverview(picks)
  }, [picks])
  return overview
}
