import React from 'react'
import I18n from 'i18n-js'
import _ from "lodash"
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

import {
  chartLine,
  chartAxisLabel
} from '@styles/_export'
import { displayPoints } from "@root/helpers/number_helpers"

const allLteqZero = (data) => _.every(data, (item) => item.points <= 0)
const allGteqZero = (data) => _.every(data, (item) => item.points >= 0)

/**
 * React component props for the chart.
 *
 * @typedef Props
 * @property {object[]} data chart data points
 * @property {String} title chart title
 * @property {String} subtitle chart subtitle
 * @property {String} demo_mode_text text shown if demo mode is active
 * @property {boolean} demo_mode activates demo mode
 * @property {boolean} invert_y inverts Y axis
 * @property {boolean} show_plus shows a positive sign '+' on positive tick values
 */

/**
 * @class
 * @extends {React.Component<Props,{},any>}
 *
 * @property {object[] | undefined} invertedData chart data points inverted for y axis inversion
 * @property {boolean} showZero says if the tick marked with '0' should be shown. (true if data points are positive AND negative)
 */
class Chart extends React.Component {
  /**
   * @constructor
   * @param {Props} props
   */
  constructor(props) {
    super(props)
    this.omitZero = (allGteqZero(props.data) && !props.invert_y) || (allLteqZero(props.data) && props.invert_y)
    this.tickFormatter = this.tickFormatter.bind(this)
  }

  render() {
    const {
      data,
      title,
      subtitle,
      demo_mode: demoMode,
      demo_mode_text: demoModeText
    } = this.props
    return (
      <React.Fragment>
        {title && subtitle &&
          <div className='chart__title'>
            <h3 >{title}</h3>
            <div className='chart__title__sub'>{subtitle}</div>
          </div>
        }
        <div className='chart__chartContainer'>
          {!demoMode &&
            <ResponsiveContainer width='100%' height='100%'>
              <AreaChart data={data}>
                <Area type='linear' dataKey='points' stroke={chartLine} fill={chartLine} fillOpacity={1} />
                <XAxis stroke={chartAxisLabel} dataKey='round' axisLine={false} tickSize={0} tickCount={5} tickMargin={4} tickFormatter={this.roundLabelShort} />
                <YAxis reversed={this.props.invert_y} stroke={chartAxisLabel} dataKey='points' axisLine={false} width={40} tickFormatter={this.tickFormatter} tickSize={0} tickMargin={8} />
                <Tooltip content={this.renderTooltip} cursor={false} />
              </AreaChart>
            </ResponsiveContainer>
          }
          {demoMode &&
            <React.Fragment>
              <ResponsiveContainer width='100%' height='100%'>
                <AreaChart data={data}>
                  <XAxis stroke={chartAxisLabel} dataKey='round' tickSize={0} tickCount={5} tickMargin={4} tick={false} />
                  <YAxis reversed={this.props.invert_y} stroke={chartAxisLabel} dataKey='points' width={40} tickFormatter={this.tickFormatter} tickSize={0} tickMargin={8} />
                </AreaChart>
              </ResponsiveContainer>
              <div className='chart__demoText'>
                {demoModeText}
              </div>
            </React.Fragment>
          }
        </div>
      </React.Fragment>
    )
  }

  roundLabelShort(round) {
    if (round === 0) return ''
    return I18n.t('chart.round_label_short', { round: round })
  }

  /**
   * Formats the tick marker on the Y axis.
   *
   * @method
   * @param {number} tick point value that is being represented
   */
  tickFormatter(tick) {
    if (this.omitZero && tick === 0) return ""

    return displayPoints(tick)
  }

  renderTooltip({ label, active, payload }) {
    if (active) {
      const val = payload[0].value
      return (
        <div className='chart__tooltip'>
          <div className='chart__tooltip__label'>{I18n.t('chart.round_label', { round: label })}</div>
          <div className='chart__tooltip__value'>{I18n.t('chart.points', { points: displayPoints(val) })}</div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default Chart
