import React, { PropsWithChildren, createContext, useContext, useState } from "react"
import Popup from "./popup"
import { max, min } from "lodash"
import I18n from "i18n-js"
import Button from "../Button"

const NUMBER_OF_SLIDES = 7

const SlideContent: React.FC<{closeTutorial: () => void}> = ({ closeTutorial }) => {

  const [slide, setSlide] = useState(0)

  const previousSlide = () => setSlide((slide) => max([slide - 1, 0])!)
  const nextSlide = () => setSlide((slide) => min([slide + 1, NUMBER_OF_SLIDES - 1])!)

  const leftButton =
    (slide === 0)
      ? { title: I18n.t("championshipbet.tutorial_popup.slide_btn.start_tutorial"), callback: nextSlide }
      : { title: I18n.t("championshipbet.tutorial_popup.slide_btn.previous"), callback: previousSlide }
  const rightButton =
    (slide === 0 || slide === NUMBER_OF_SLIDES - 1)
      ? {
          title: (slide === 0)
            ? I18n.t("championshipbet.tutorial_popup.slide_btn.play_now")
            : I18n.t("championshipbet.tutorial_popup.slide_btn.play"),
          callback: closeTutorial,
        }
      : { title: I18n.t("championshipbet.tutorial_popup.slide_btn.next"), callback: nextSlide }

  return <div className="championshipBet__tutorialPopup__slide">
    <div className={`championshipBet__tutorialPopup__slideScreenshot slideScreenshot--slide${slide + 1}`} />
    <div className="championshipBet__tutorialPopup__slideContent">
      {I18n.t(`championshipbet.tutorial_popup.slide_content.slide${slide + 1}`)}
    </div>
    <div className="championshipBet__tutorialPopup__slideButtons">
      <Button primary title={leftButton.title} onClick={leftButton.callback} />
      <Button primary title={rightButton.title} onClick={rightButton.callback} />
    </div>
  </div>
}

const TutorialPopupContext = createContext({
  openTutorial: () => {}
})

const useTutorialPopup = () => useContext(TutorialPopupContext)

export const WithTutorialPopup: React.FC<PropsWithChildren<{}>> = ({ children }) => {

  const [open, setOpen] = useState(false)

  const openTutorial = () => setOpen(true)
  const closeTutorial = () => setOpen(false)

  return <TutorialPopupContext.Provider value={{ openTutorial }}>
    <Popup isOpen={open} closePopup={closeTutorial}>
      <SlideContent closeTutorial={closeTutorial} />
    </Popup>
    {children}
  </TutorialPopupContext.Provider>
}

export default useTutorialPopup
