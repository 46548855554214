import axios from 'axios'
import I18n from 'i18n-js'
import _ from "lodash"
import { BetId, SquadBetPick, TSquadBet, WithSecondsLeft } from './types'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import betStatus from '@root/bet_utils/presenter/bet_status'

const pollBetScoring = (betId: BetId, authenticityToken: string, onOk: (bet: TSquadBet) => void, onError: (errorMsg?: string) => void) => {
  const data = {
    bet_id: betId,
    authenticity_token: authenticityToken
  }

  axios.post('/bet_scoring', data).then((response) => {
    onOk(response.data.bet)
  }).catch(() => {
    onError(I18n.t('bet.response.fail'))
  })
}

export const addPolledScoringToBet = (bet: WithSecondsLeft<TSquadBet>, authenticityToken: string, pollEverySecIfBetOver: number, setPicks: Dispatch<SetStateAction<SquadBetPick[]>>) => {
  const [polledBet, setPolledBet] = useState<TSquadBet>()

  useEffect(() => {
    let interval: number
    if (betStatus.isMatchInProgress(bet) && !bet.from_other) {
      interval = window.setInterval(() => {
        pollBetScoring(bet.bet_id, authenticityToken,
          (bet) => {
            setPolledBet(bet)
            setPicks(bet.picks || [])
          },
          (err) => { /* ignore */ })
      }, pollEverySecIfBetOver * 1000)
    }

    return () => { // cleanup
      if (_.isNumber(interval)) {
        window.clearInterval(interval)
      }
    }
  },[betStatus.isMatchInProgress(bet), !!bet.from_other])

  if (polledBet) {
    bet.event_state =  polledBet.event_state
    bet.event_over = polledBet.event_over
    if (_.isNumber(polledBet.total_score)) { bet.total_score = polledBet.total_score }
    if (polledBet.players) { bet.players = polledBet.players }
    if (polledBet.teams) { bet.teams = polledBet.teams }
    if (polledBet.scoring_detail) { bet.scoring_detail = polledBet.scoring_detail }
  }
}

export default pollBetScoring
