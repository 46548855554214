import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import { SquadBetPlayer, SquadBetTeam } from "./types"
import _ from "lodash"
import useResize from "use-resize"
import { PlayerItemInner, TeamItemInner } from "./ui"
import I18n from "i18n-js"
import { useSquadBet } from "./context"
import { useDevice } from "@root/misc/device"
import { disableBodyScroll, enableBodyScroll } from "@root/misc/disableBodyScroll"

export interface PickerEntry<ItemType> {
  item: ItemType
  alreadyInSquad?: Boolean
  sameAsAlreadyInThisSlot?: Boolean
  cantPickReason?: string
}

type PickerFn<ItemType> = (entries: PickerEntry<ItemType>[], onPick: (item?: ItemType) => void, onAbort?: () => void) => void

const PickerContext = createContext<{pickPlayer: PickerFn<SquadBetPlayer>, pickTeam: PickerFn<SquadBetTeam>}>({pickPlayer: () => {}, pickTeam: () => {}})

type PickerPayload =
  | {
      type: "player"
      entries: PickerEntry<SquadBetPlayer>[]
      onPick(item?: SquadBetPlayer): void
      onAbort?(): void
    }
  | {
      type: "team"
      entries: PickerEntry<SquadBetTeam>[]
      onPick(item?: SquadBetTeam): void
      onAbort?(): void
  }

export const WithPicker: React.FC<PropsWithChildren<{}>> = ({children}) => {
  const [payload, setPayload] = useState<PickerPayload>()
  const {height: windowHeight} = useResize()
  const { bet } = useSquadBet()
  const { isPhone, isDesktopUp, isTabletUp } = useDevice()

  const maxPickerHeight = Math.floor(windowHeight * .8)

  useEffect(() => {
    if (!!payload) {
      disableBodyScroll()
    } else {
      enableBodyScroll()
    }
  }, [!!payload])
  const closePicker = () => setPayload(undefined)

  useEffect(() => { // make sure body can scroll when picker unmounted (e.g. when countdown runs out and bet closes itself)
    return () => { // cleanup
      enableBodyScroll()
    }
  }, [])

  return <PickerContext.Provider value={{
    pickPlayer: (entries, onPick, onAbort) => {
      setPayload({type: "player", entries, onPick, onAbort})
    },
    pickTeam: (entries, onPick, onAbort) => {
      setPayload({type: "team", entries, onPick, onAbort})
    }
  }}>
    {payload && <>
      <div className="squadBet__picker__backdrop" />
      <div className="squadBet__picker__holder" onClick={closePicker}>
        <div className="squadBet__picker__positioner" onClick={(evt) => evt.stopPropagation()}>
          <div className="squadBet__picker">
            <a className="squadBet__picker__close" onClick={closePicker}>{I18n.t("squadbet.action_close_picker")}</a>
            <div className="squadBet__picker__inner" style={{maxHeight: `${maxPickerHeight}px`}}>
              {payload.type === "team" && _.map(payload.entries, (entry) => {
                // ------ TEAM ------
                return <div key={entry.item.id} className={`squadBet__picker__item squadBet__picker__item--team ${entry.alreadyInSquad ? "--already" : ""}`}>
                  <a className="squadBet__picker__item__inner" onClick={() => {payload.onPick(entry.item); closePicker()}}>
                    <div className="squadBet__picker__item__imgHolder">
                      <TeamItemInner team={entry.item} />
                    </div>
                    <div className="squadBet__picker__item__txtHolder">
                      <h3>{entry.item.name}</h3>
                    </div>
                  </a>
                </div>})}
              {payload.type === "player" && (() => {
                // ------ PLAYER ------
                const entriesWithClear: ("clearSlot" | PickerEntry<SquadBetPlayer>)[] = [/* "clearSlot" ,*/ ...payload.entries]
                const numCols = isPhone
                  ? 2
                  : (isDesktopUp ? 4 : 3)
                const perCol = Math.ceil(entriesWithClear.length / numCols)
                console.log(_.map(payload.entries, e => e.item.team_key))
                return _.times(numCols, (colIndex) => {
                  return <div>
                    {_.map(entriesWithClear.slice(colIndex * perCol, (colIndex + 1) * perCol), (entry) => {
                      if (entry === "clearSlot") {
                        return <div className={`squadBet__picker__item squadBet__picker__item--player`} key="clear">
                          <a className="squadBet__picker__item__inner" onClick={() => {payload.onPick(undefined); closePicker()}}>
                            <div className="squadBet__picker__item__imgHolder" />
                            <div className="squadBet__picker__item__txtHolder">
                              <h3>{I18n.t("squadbet.action_clear_slot")}</h3>
                            </div>
                          </a>
                        </div>
                      } else {
                        entry = entry as PickerEntry<SquadBetPlayer>
                        console.log(entry.item.team_key)
                        const Inner = <>
                          <div className="squadBet__picker__item__imgHolder">
                            <PlayerItemInner player={entry.item} />
                            {entry.item.captain && <span className="squadBet__picker__item__captainMarker" />}
                          </div>
                          <div className="squadBet__picker__item__txtHolder">
                            <h3>{entry.item.name}</h3>
                            {entry.item.stats &&
                              <div className="--stats">
                                <div>
                                  <span>{I18n.t("squadbet.stat_player_rank_label")}</span>
                                  <strong>{I18n.t("squadbet.stat_player_rank", {rank: entry.item.stats.rank, total: entry.item.stats.total_ranks})}</strong>
                                </div>
                                <div>
                                  <span>{I18n.t("squadbet.stat_picked_by_label")}</span>
                                  <strong>{`${entry.item.stats.perc_picked}%`}</strong>
                                </div>
                              </div>}
                            {entry.cantPickReason
                              ? <div className="--error">{entry.cantPickReason}</div>
                              : entry.item.team_key && <div className="--team">{_.find(bet.teams || [], (t) => t.team_key === entry.item.team_key)?.name}</div>}
                          </div>
                        </>
    
                        return <div className={`squadBet__picker__item ${entry.alreadyInSquad ? "--already" : ""} ${entry.cantPickReason ? "--cant" : "--can"}`} key={entry.item.id}>
                          {entry.cantPickReason
                            ? <span className="squadBet__picker__item__inner">
                                {Inner}
                              </span>
                            : <a className="squadBet__picker__item__inner" onClick={entry.cantPickReason ? undefined : () => {payload.onPick(entry.item); closePicker()}}>
                                {Inner}
                              </a>}
                          
                        </div>
                      }
                    })}
                  </div>
                })
              })()}
            </div>
          </div>
        </div>
      </div></>}
    {children}
  </PickerContext.Provider>
}

const usePicker = () => useContext(PickerContext)

export default usePicker
