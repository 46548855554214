import { useEffect, useRef, useState } from "react"
import { Signal } from "signals"

import exportGlobally from "./exportGlobally"

type Theme = "dark" | "light"

const FORCE_THEME: Theme | undefined = "dark"

const defaultTheme: Theme = FORCE_THEME || "light"
const localStorageKey = "neoTheme"
const domAttr = "data-neo-theme"

const findThemeFromRefParentsOrDefault = (node: HTMLElement): Theme => {  
  if (FORCE_THEME) return FORCE_THEME
  let cur: HTMLElement | null = node
  while (cur = cur.parentElement) {
    if (cur.hasAttribute(domAttr)) { return cur.getAttribute(domAttr) as Theme }
  }
  return __theme
}

const useTheme = () => {
  const ref = useRef<HTMLElement | null>(null) as React.MutableRefObject<HTMLElement | null>
  const [theme, setTheme] = useState(__theme)
  const latestTheme = useRef(theme)
  latestTheme.current = theme
  const latestRef = useRef<HTMLElement | null>(null) as React.MutableRefObject<HTMLElement | null>

  const refindTheme = () => {
    if (ref.current) {
      const newTheme = findThemeFromRefParentsOrDefault(ref.current)
      if (newTheme !== latestTheme.current) { setTheme(newTheme) }
    }
  }

  useEffect(() => { // check on themeChanged
    const onThemeChanged = () => {
      refindTheme()
    }
    themeChanged.add(onThemeChanged)

    return () => { // cleanup
      themeChanged.remove(onThemeChanged)
    }
  }, [])

  useEffect(() => { // check first time and again when ref changes
    if (ref.current && latestRef.current !== ref.current) {
      latestRef.current = ref.current
      refindTheme()
    }
  })

  return {
    theme,
    ref
  }
}

const getThemeFromStoreOrBrowserPreference = (): Theme => {
  if (FORCE_THEME) return FORCE_THEME
  try {
    if (localStorage.getItem(localStorageKey)) {
      if (localStorage.getItem(localStorageKey) === "dark") { return "dark" }
      return "light"
    } 
    if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) { return "dark" }
  } catch {
    return defaultTheme
  }
  return defaultTheme
}

const writeThemeToDOM = (theme: Theme) => {
  document.documentElement.setAttribute(domAttr, theme)
}

let __theme: Theme
const themeChanged = new Signal()

const initialize = () => {
  if (__theme) { return }
  __theme = getThemeFromStoreOrBrowserPreference()
  writeThemeToDOM(__theme)
}

const setTheme = (theme: Theme) => {
  if (FORCE_THEME) return // don't change theme due to FORCE_THEME being active
  if (theme !== __theme) { localStorage.setItem(localStorageKey, theme) }
  __theme = theme
  writeThemeToDOM(theme)
  themeChanged.dispatch()
}

const clearStoredSetting = () => {
  try {
    localStorage.removeItem(localStorageKey)
  } catch {}
}

const getTheme = () => { return __theme }

const toggleTheme = () => { setTheme(__theme === "dark" ? "light" : "dark") }

exportGlobally("theme", {toggleTheme, clearStoredSetting, setTheme})
export { useTheme, initialize, themeChanged, toggleTheme, setTheme, clearStoredSetting, getTheme }
