export const disableBodyScroll = () => {
  const scrollY = window.scrollY
  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollY}px`;
}

export const enableBodyScroll = () => {
  if (document.body.style.position !== "fixed") { return }
  const scrollY = document.body.style.top;
  document.body.style.position = '';
  document.body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
}
