import React, { PropsWithChildren, createContext, useContext, useState } from "react"
import { TSquadBet, WithSecondsLeft, SquadBetPick } from "./types"
import { SquadSlots, getSquadSlots } from "./helper"
import { addSecondsLeftToBet } from '@root/bet_utils/use_deadline_countdown'
import savePicks from '@root/bet_utils/save_picks'
import DisplaySaveResult, { createOkResult, createErrorResult } from '@root/bet_utils/DisplaySaveResult'
import LegacyBetContext from "@root/bet_utils/context/bet"
import _ from "lodash"
import { addPolledScoringToBet } from "./pollBetScoring"

const _SquadBetContext = createContext<{
  bet: WithSecondsLeft<TSquadBet>
  squadSlots: SquadSlots
  changePicks(buildNewPicks: (oldPicks: SquadBetPick[]) =>  SquadBetPick[]): void
  displaySaveResult?: any,
  authToken: string
}>({} as any)

const filledPicksArray = (picks: any[]) => {
  return _.times(7, (index) => picks?.[index] || null)
}

export const WithSquadBet: React.FC<PropsWithChildren<{initialBet: TSquadBet, authToken: string}>> = ({initialBet, authToken, children}) => {

  const [ picks, setPicks ] = useState(initialBet.picks || [])
  const bet = {
    ...initialBet,
    picks
  }
  addSecondsLeftToBet(bet) // seconds left automatically calculated
  addPolledScoringToBet(bet as WithSecondsLeft<TSquadBet>, authToken, 60 * 2, setPicks)
  const [ displaySaveResult, setDisplaySaveResult ] = useState<any>()

  const changePicks = (buildNewPicks: (oldPicks: SquadBetPick[]) =>  SquadBetPick[]) => {
    const newPicks = buildNewPicks(picks.slice(0))
    setPicks(newPicks) // force component update
    savePicks(bet, authToken, filledPicksArray(newPicks),
      /* OK    */ (msg: string) => { setDisplaySaveResult(createOkResult(msg)) },
      /* ERROR */ (errorMsg: string) => { setDisplaySaveResult(createErrorResult(errorMsg)) })
  }

  return <LegacyBetContext.Provider value={bet}>
    <_SquadBetContext.Provider value={{
      changePicks,
      bet: bet as any,
      squadSlots: getSquadSlots(bet),
      displaySaveResult,
      authToken
    }}>
      {children}
    </_SquadBetContext.Provider>
  </LegacyBetContext.Provider>
}

export const useSquadBet = () => {
  return useContext(_SquadBetContext)
}
