import React from 'react'
import PropTypes from 'prop-types'
import _ from "lodash"

class Countdown extends React.PureComponent {
  constructor (props) {
    super(props)

    const nowMillis = Date.now()
    let end
    if (props.end) {
      end = props.end
    } else if (props.secondsLeft && props.secondsLeft > 0) {
      end = nowMillis + (props.secondsLeft * 1000)
    } else {
      end = nowMillis
    }

    this.state = {
      countdown: this.countdownLeft(new Date(nowMillis), new Date(end)),
      end: end,
      intervalId: undefined,
      initialTimeoutId: undefined
    }
  }

  componentDidMount () {
    const leftMilliSeconds = this.countdownLeft(new Date(Date.now()), new Date(this.state.end)).seconds * 1000

    this.state.initialTimeoutId = setTimeout(() => {
      this.updateCountdown()
      this.state.intervalId = setInterval(() => this.updateCountdown(), 1000 * 60)
    }, leftMilliSeconds)
  }

  componentWillUnmount () {
    clearTimeout(this.state.initialTimeoutId)
    clearInterval(this.state.intervalId)
  }

  updateCountdown () {
    this.setState({
      countdown: this.countdownLeft(new Date(Date.now()), new Date(this.state.end))
    })
  }

  countdownLeft (dateStart, dateEnd) {
    const dateDiff = dateEnd - dateStart

    const leftDays = dateDiff / 86400000
    const leftHours = (leftDays - Math.floor(leftDays)) * 24
    const leftMinutes = (leftHours - Math.floor(leftHours)) * 60
    const leftSeconds = (leftMinutes - Math.floor(leftMinutes)) * 60

    let days = 0
    let hours = 0
    let minutes = 0
    let seconds = 0

    if (dateStart < dateEnd) {
      days = Math.floor(leftDays)
      hours = Math.floor(leftHours)
      minutes = Math.floor(leftMinutes)
      seconds = Math.floor(leftSeconds)
    }

    return { days, hours, minutes, seconds }
  }

  numberToDigitStrings(num) {
    const paddedString = num.toString().padStart(2, "0")
    const digits = paddedString.split("")
    return digits
  }

  render () {
    const { days, hours, minutes } = this.state.countdown

    return (
      <div className={'countdown' + (this.props.invert ? " countdown--invert" : "")}>
        <div className='countdown__inner'>
          <div className='countdown__item'>
            <span>{_.padStart(days.toString(), 2, "0")}</span>
            <span>{this.props.short_day}</span>
          </div>
          <div className='countdown__item'>
            <span>{_.padStart(hours.toString(), 2, "0")}</span>
            <span>{this.props.short_hour}</span>
          </div>
          <div className='countdown__item'>
            <span>{_.padStart(minutes.toString(), 2, "0")}</span>
            <span>{this.props.short_minutes}</span>
          </div>
        </div>
        <div className='countdown__togoSide'>
          <span>TO</span>
          <span>GO</span>
        </div>
      </div>
    )
  }
}

Countdown.propTypes = {
  end: PropTypes.string,
  title: PropTypes.string,
  short_day: PropTypes.string,
  short_hour: PropTypes.string,
  short_minutes: PropTypes.string,
  invert: PropTypes.bool
}
export default Countdown
