import { useDevice } from "@root/misc/device"
import React, { useCallback, useRef, useState } from "react"

interface TeaserVideoProps {
  desktopSrc: string
  mobileSrc: string
}
const TeaserVideo: React.FC<TeaserVideoProps> = ({ desktopSrc, mobileSrc }) => {
  const { isPhone } = useDevice()
  const [playing, setPlaying] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  const onClick = useCallback(() => {
    setPlaying((value) => {
      if (videoRef.current) {
        if (value) {
          videoRef.current.pause()
        } else {
          videoRef.current.play()
        }
        return !value
      }
      return value
    })
  }, [videoRef.current])

  return <div className="landing__teaser__video__container">
    <div className="landing__teaser__videoWrapper" onClick={onClick}>
      <video ref={videoRef} className="landing__teaser__video" loop>
        <source src={isPhone ? mobileSrc : desktopSrc} type="video/mp4" />
      </video>
      {!playing && <div className="landing__teaser__video__playWrapper">
        <div className="landing__teaser__video__playWrapperBg" />
        <svg className="landing__teaser__video__playButton" height="48" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 52" width="52" xmlns="http://www.w3.org/2000/svg">
          <path d="M48 26L0 0V52L48 26Z"></path>
        </svg>
      </div>}
    </div>
  </div>
}

export default TeaserVideo