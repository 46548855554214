import React, { MouseEventHandler, PropsWithChildren, ReactNode } from "react"
import { ChampionshipBetPick, ChampionshipBetTeam, PickValue, TeamId } from "./types"
import _, {isEmpty, pick, some} from "lodash"
import I18n from "i18n-js"
import { useChampionshipBet } from "./context"
import usePicker from "./usePicker"
import {
  NUMBER_OF_RANKS, buildPicks, pickIsSet,
} from "./helper"

interface SquadSectionProps {
  title: string
  teamTitle: string
  teamInfo: string
  subTitle?: string | ReactNode
  subInfo: string
  squadItems: ReactNode[]
  teamItem: ReactNode
  subItem?: ReactNode
}

export const SquadSection: React.FC<SquadSectionProps> = ({
  title,
  teamTitle,
  teamInfo,
  subTitle,
  subInfo,
  squadItems,
  subItem,
  teamItem,
}) => {
  return <div className="squadBet__squadSection">
    <div className="squadBet__squadSection__squadHolder">
      <div className="squadBet__squadSection__squadHolder__inner">
        <div className="squadBet__squadSection__squadHolder__title">
          <h2>{title}</h2>
        </div>
        <div className="squadBet__squadSection__squadHolder__items">
          <SquadPlayerItemsHolder items={squadItems} />
        </div>
      </div>
    </div>
    <div className="squadBet__squadSection__subAndTeamHolder">
      <div className="squadBet__squadSection__subHolder">
        <div className="squadBet__squadSection__subHolder__inner">
          <div className="squadBet__squadSection__subHolder__info">
            {subTitle && <h2>{subTitle}</h2>}
            <p>{subInfo}</p>
          </div>
          {subItem && <div className="squadBet__squadSection__subHolder__item">
            {subItem}
          </div>}
        </div>
      </div>
      <div className="squadBet__squadSection__teamHolder">
        <div className="squadBet__squadSection__teamHolder__inner">
          <div className="squadBet__squadSection__teamHolder__info">
            <h2>{teamTitle}</h2>
            <p>{teamInfo}</p>
          </div>
          <div className="squadBet__squadSection__teamHolder__item">
            {teamItem}
          </div>
        </div>
      </div>
    </div>
  </div>
}

export const SquadPlayerItemsHolder: React.FC<{items: ReactNode[]}> = ({items}) => {
  return <div className="squadBet__playerItemsHolder">
    {_.map(items, (i, index) => {
      return <div key={index} className="squadBet__playerItemsHolder__item">{i}</div>
    })}
  </div>
}

export const TeamItemInner: React.FC<{team: ChampionshipBetTeam}> = ({team}) => {
  return <div className={`squadBet__teamPickItem livTeam--${team.team_key}`} />
}

export const PlusItemInner: React.FC = () => {
  return <div className="squadBet__plusItemInner"><span>{I18n.t("squadbet.action_pick")}</span></div>
}

export const PlayerDummyItemInner: React.FC = () => {
  return <div className="squadBet__playerDummyPickItem" />
}

export const EmptyItemInner: React.FC<{emptyReason: "nothingPicked" | "censored"}> = ({emptyReason}) => {
  return <div className="squadBet__emptyItemInner">
    <span>{emptyReason === "nothingPicked" ? "-" : "?"}</span>
  </div>
}

export interface PickItemHolderProps {
  title?: string
  placeholderTitle?: string
  score?: number
  greyout?: Boolean
  aboveTitle?: string
  above?: ReactNode
  below?: ReactNode
  stats?: (string[])[]
  actionButton?: {text: string, action(): void}
}
export const PickItemHolder: React.FC<PropsWithChildren<PickItemHolderProps>> = ({
  above,
  aboveTitle,
  actionButton,
  children,
  greyout,
  placeholderTitle,
  stats,
  title,
  below,
}) => {
  return <div className={`squadBet__pickItemHolder${greyout ? " squadBet__pickItemHolder--greyout" : ""}`}>
    {above && <div className="squadBet__pickItemHolder__above">{above}</div>}
    <div className="squadBet__pickItemHolder__pick">{children}</div>
    <div className="squadBet__pickItemHolder__textStuff">
      {aboveTitle && <div className="squadBet__pickItemHolder__aboveTitle">{aboveTitle}</div>}
      {placeholderTitle && <div className="squadBet__pickItemHolder__placeholderTitle">{placeholderTitle}</div>}
      {title && <div className="squadBet__pickItemHolder__title">{title}</div>}
      {stats && <div className="squadBet__pickItemHolder__stats">
        {_.map(stats, (s, index) => {
          return <div key={index}><span>{s[0]}</span><strong>{s[1]}</strong></div>
        })}</div>}
      {actionButton && <div className="squadBet__pickItemHolder__actionButton">
        <a onClick={() => actionButton.action()}>{actionButton.text}</a></div>}
    </div>
    {below && <div className="squadBet__pickItemHolder__below">{below}</div>}
  </div>
}

interface PickItemProps {
  actionable: Boolean
  onClick?(): void
  withChangeIcon?: Boolean
}
export const PickItem: React.FC<PropsWithChildren<PickItemProps>> = ({
  actionable,
  onClick,
  withChangeIcon,
  children
}) => {
  const Inner = <>
      <span className="squadBet__pickItem__inner">{children}</span>
      {withChangeIcon && <span className="squadBet__pickItem__changeIcon" />}
    </>

  return actionable
    ? <a className="squadBet__pickItem" onClick={() => onClick?.()}>{Inner}</a>
    : <span className="squadBet__pickItem">{Inner}</span>
}

interface SelectTeamProps {
  index: number
  choices: ChampionshipBetTeam[]
  pickIndices: number[]
  parentClass: string
  team?: ChampionshipBetTeam | null
  emptyMessage?: string
  variant?: string
  isCorrect?: boolean
  isOpen: boolean
}
export const SelectTeam: React.FC<SelectTeamProps> = ({
  index,
  choices,
  pickIndices,
  parentClass,
  team,
  emptyMessage,
  variant,
  isCorrect,
  isOpen,
}) => {
  const { bet, changePicks } = useChampionshipBet()

  const { pickTeam } = usePicker()

  const makePick = () => {
    if (isOpen && !bet.from_other) {
      pickTeam(
        choices.map((team) => {
          return {
            item: team,
            alreadyInSquad: some(pickIndices, (pickIndex) => bet.picks?.[pickIndex] === team.id)
          }
        }),
        (item) => {
          if (!item) return
          changePicks(
            (prev) => buildPicks(item, prev, pickIndices, index)
          )
        }
      )
    }
  }

  if (isEmpty(choices) && !team && !bet.shadow) {
    return <div className={`${parentClass} ${parentClass}--empty`}>
      <div className={`${parentClass}Label`}>
        {emptyMessage || I18n.t("championshipbet.ui.select_team.default_empty")}
      </div>
    </div>
  } else {
    if (team) {
      return (
        <div
          className={[
            parentClass,
            `${parentClass}--picked`,
            `livTeam__teamBoxCol--${team.team_key}`,
            bet.from_other ? `${parentClass}--fromOther` : "",
            (!isOpen) ? `${parentClass}--notOpen` : "",
            (!isOpen && isCorrect) ? `${parentClass}--correct` : "",
          ].join(" ")}
          onClick={isEmpty(choices) ? () => {} : makePick}
        >
          <div className={`${parentClass}__rank`}>#{team.stats.rank}</div>
          <div className="livTeam__logo" />
        </div>
      )
    } else {
      if (isOpen) {
        return (
          <div
            className={[
              parentClass,
              `${parentClass}--pickable`,
              variant ? `${parentClass}--${variant}` : "",
              bet.from_other ? `${parentClass}--fromOther` : "",
            ].join(" ")}
            onClick={makePick}
          >
            <div className={`${parentClass}Label`}>
              {I18n.t("championshipbet.ui.select_team.pick_team")}
            </div>
          </div>
        )
      } else {
        return (
          <div
            className={[
              parentClass,
              `${parentClass}--pickable`,
              `${parentClass}--notOpen`,
              variant ? `${parentClass}--${variant}` : "",
              bet.from_other ? `${parentClass}--fromOther` : "",
            ].join(" ")}
            onClick={makePick}
          >
            <div className={`${parentClass}Label`}>
              {I18n.t("championshipbet.ui.select_team.nothing_picked")}
            </div>
          </div>
        )
      }
    }
  }
}

interface ChangeTeamProps {
  index: number
  pickIndex: number
  picked: boolean
  choices: ChampionshipBetTeam[]
  pickIndices: number[]
  parentClass: string
  team: ChampionshipBetTeam
  isOpen: boolean
  isWinner: boolean
  isScored: boolean
  resetIndices: number[]
  isAlreadyInSquad: (teamId: TeamId) => boolean
}
export const ChangeTeam: React.FC<ChangeTeamProps> = ({
  index,
  pickIndex,
  picked,
  choices,
  pickIndices,
  parentClass,
  team,
  resetIndices,
  isOpen,
  isWinner,
  isScored,
  isAlreadyInSquad,
}) => {
  const { changePicks, bet: { from_other } } = useChampionshipBet()

  const { pickTeam } = usePicker()

  const makePick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (isOpen && !from_other) {
      pickTeam(
        choices.map((team) => {
          return {
            item: team,
            alreadyInSquad: isAlreadyInSquad(team.id),
          }
        }),
        (item) => {
          if (!item) return
          changePicks(
            (prev) => buildPicks(item, prev, pickIndices, index, resetIndices)
          )
        }
      )
    }
    e.stopPropagation()
  }

  if (isEmpty(choices)) {
    return <div className={parentClass}>
      -
    </div>
  } else {
    return (
      <SelectWinner
        index={pickIndex}
        picked={picked}
        className={[
          parentClass,
          `${parentClass}--picked`,
          `livTeam__teamBoxCol--${team.team_key}`,
        ].join(" ")}
        team={team}
        parentClass={parentClass}
        isOpen={isOpen}
        isWinner={isWinner}
        isScored={isScored}
        right
      >
        <div
          className={
            `${parentClass}ChangeIcon` + (
              (!isOpen)
                ? ` ${parentClass}ChangeIcon--notOpen`
                : ""
            )
          }
          onClick={makePick}
        />
      </SelectWinner>
    )
  }
}

type SelectWinnerProps = PropsWithChildren<{
  index: number,
  picked: boolean,
  className: string,
  team: ChampionshipBetTeam | null,
  parentClass: string,
  isOpen: boolean,
  left?: boolean,
  right?: boolean,
  isWinner?: boolean,
  isScored: boolean,
  resetIndices?: number[],
}>
export const SelectWinner: React.FC<SelectWinnerProps> = ({
  index,
  picked,
  className,
  team,
  parentClass,
  isOpen,
  children,
  left,
  right,
  isWinner,
  isScored,
  resetIndices,
}) => {

  const { changePicks, bet: { from_other } } = useChampionshipBet()

  const classes = [
    className,
    ((isOpen || !isScored) ? picked : isWinner) ? `${parentClass}--winner` : "",
    (!isOpen && isScored && picked && !isWinner) ? `${parentClass}--wrongWinner` : "",
    (!isOpen) ? `${parentClass}--notOpen` : "",
    from_other ? `${parentClass}--fromOther` : "",
    left ? `${parentClass}--left` : "",
    right ? `${parentClass}--right` : "",
  ].join(" ")

  const pickWinner = () => {
    if (isOpen && !from_other && team) {
      changePicks(
        (prev) => {
          const newPicks = prev.concat()
          newPicks[index] = team.id
          console.log(resetIndices)
          if (resetIndices) {
            for (let resetIndex of resetIndices) {
              console.log(prev[resetIndex], prev[index])
              if (prev[resetIndex] === prev[index]) {
                newPicks[resetIndex] = undefined
              }
            }
          }
          return newPicks
        }
      )
    }
  }

  return <div
    className={classes}
    onClick={pickWinner}
  >
    <div className="livTeam__logo" />
    {team && <div className={`${parentClass}__rank`}>#{team.stats.rank}</div>}
    {children}
    {(!isOpen && isScored && isWinner) && <div className="correct">{I18n.t("championshipbet.ui.select_winner.winner")}</div>}
    {(!isOpen && isScored && picked && !isWinner) && <div className="wrong">{I18n.t("championshipbet.ui.select_winner.pick")}</div>}
  </div>
}

interface RankingRowProps {
  index: number
  team?: ChampionshipBetTeam | null
  currentPick: ChampionshipBetPick
  pickIndices: number[]
  options: ChampionshipBetTeam[]
  isCorrect: boolean
  isOpen: boolean
}
export const RankingRow: React.FC<RankingRowProps> = ({
  index,
  currentPick,
  team,
  pickIndices,
  options,
  isCorrect,
  isOpen,
}) => {

  const { changePicks, bet: { from_other } } = useChampionshipBet()

  const upClasses = [
    "rankingItem__spinnerButton",
    "rankingItem__spinnerButton--up",
    (index === 0
      || !pickIsSet(currentPick)
      || !isOpen
      || from_other) ? "rankingItem__spinnerButton--inactive" : "",
  ].join(" ")
  const downClasses = [
    "rankingItem__spinnerButton",
    "rankingItem__spinnerButton--down",
    (index === NUMBER_OF_RANKS - 1
      || !pickIsSet(currentPick)
      || !isOpen
      || from_other) ? "rankingItem__spinnerButton--inactive" : "",
  ].join(" ")

  const swapUp = () => {
    // inactive on topmost item or if empty
    if (index > 0 && pickIsSet(currentPick) && isOpen && !from_other) {
      const pickIndex = pickIndices[index]
      changePicks((prev) => {
        const newPicks = prev.concat()
        newPicks[pickIndex] = prev[pickIndex - 1]
        newPicks[pickIndex - 1] = prev[pickIndex]
        return newPicks
      })
    }
  }
  const swapDown = () => {
    // inactive on bottommost item or if empty
    if (index < NUMBER_OF_RANKS - 1 && pickIsSet(currentPick) && isOpen && !from_other) {
      const pickIndex = pickIndices[index]
      changePicks((prev) => {
        const newPicks = prev.concat()
        newPicks[pickIndex] = prev[pickIndex + 1]
        newPicks[pickIndex + 1] = prev[pickIndex]
        return newPicks
      })
    }
  }

  return <>
    <div
      key={`ranking_slot_${index}`}
      className={"championshipBet__rankingRow" + (index === 0 ? " championshipBet__rankingRow--first" : "")}
    >
      <div className="championshipBet__rankingRow__rank">{index + 1}</div>
      <SelectTeam
        index={pickIndices[index]}
        choices={options}
        pickIndices={pickIndices}
        parentClass={"championshipBet__rankingRow__teamBox"}
        team={team}
        emptyMessage={I18n.t("championshipbet.ui.ranking_row.empty")}
        isCorrect={isCorrect}
        isOpen={isOpen}
      />
      <div className="rankingItem__spinnerButtons">
        <div onClick={swapUp} className={upClasses} />
        <div onClick={swapDown} className={downClasses} />
      </div>
    </div>
  </>
}

interface PairingRowProps {
  index: number
  points: PickValue
  opponentPoints?: PickValue
  matchText: string
}
export const PairingRow: React.FC<PropsWithChildren<PairingRowProps>> = ({
  index,
  points,
  opponentPoints,
  matchText,
  children,
}) => {
  const isScored = !!points || points === 0
  const pickResult = points !== 0 ? "correct" : "wrong"

  const matchHeaderClasses = [
    "championshipBet__pairingRow",
    "championshipBet__pairingRow--matchHeader",
    isScored ? `championshipBet__pairingRow--${pickResult}` : "",
  ].join(" ")

  return <>
    <div className={matchHeaderClasses}>
      <div className="championshipBet__pairingRow__matchHeader">
        {I18n.t("championshipbet.ui.pairing_row.match", { index: index + 1 })}
      </div>
      {isScored
        ? <div className="championshipBet__pairingRow__matchText">
            {I18n.t(`championshipbet.ui.pairing_row.${pickResult}`)}
          </div>
        : <div className="championshipBet__pairingRow__matchText">{matchText}</div>
      }
      {isScored && <div className="championshipBet__pairingRow__matchPoints">{opponentPoints ? points + opponentPoints : points}</div>}
    </div>
    <div key={`round1_pairing_${index}`} className="championshipBet__pairingRow">
      {children}
    </div>
  </>
}

interface PickedProgressProps {
  pickedPercent: number
}
export const PickedProgress: React.FC<PickedProgressProps> = ({ pickedPercent }) => {
  return <div className="championshipBet__pickedProgress">
    <div>{I18n.t("championshipbet.ui.section_header.percent_picked", { pickedPercent })}</div>
    <div className="championshipBet__progressBar">
      <div className="championshipBet__progressIndicator" style={{width: `${pickedPercent}%`}}></div>
    </div>
  </div>
}

interface SectionHeaderProps {
  linkTarget: string
  title: string
  infoText: string
  pickedPercent: number
  points?: number
}
export const SectionHeader: React.FC<SectionHeaderProps> = ({
  linkTarget,
  title,
  infoText,
  pickedPercent,
  points,
}) => {
  return <div id={linkTarget} className="championshipBet__sectionHeader">
    <div className="championshipBet__sectionHeader__title">{title}</div>
    <div className="championshipBet__sectionHeader__subTitle">
      <div className="championshipBet__sectionHeader__infoText">{infoText}</div>
      <PickedProgress pickedPercent={pickedPercent} />
    </div>
    {(points || points === 0) && <div className="championshipBet__sectionHeader__points">{points}</div>}
  </div>
}


interface SelectionRowProps {
  team: ChampionshipBetTeam,
  isOpen: boolean,
  isWinner?: boolean,
  isScored: boolean,
  picked: boolean,
  pickIndices: number[],
  resetIndices?: number[]
  currentIndex: number,
}

export const SelectionRow: React.FC<SelectionRowProps> = ({
                                                      team,
                                                      isOpen,
                                                      isWinner,
                                                      isScored,
                                                      picked,
                                                      pickIndices,
                                                      resetIndices,
                                                      currentIndex
                                                    }) => {
  const { changePicks, bet: { from_other } } = useChampionshipBet()

  var classNames = [
    `championshipBet__pairingRow__teamBox`,
    `livTeam__teamBoxCol--${team.team_key}`
  ]

  if (isOpen){  classNames.push(`championshipBet__pairingRow--pickable`) }
  if (picked){
    classNames.push(`championshipBet__pairingRow__teamBox--right`)
    classNames.push(`championshipBet__pairingRow__teamBox--winner`)
  }

  const pickIndex = pickIndices[currentIndex]

  const pickWinner = () => {
    if (isOpen && !from_other && team && !picked) {
      changePicks(
          (prev) => {
            const newPicks = prev.concat()
            newPicks[pickIndex] = team.id
            console.log(resetIndices)
            if (resetIndices) {
              for (let resetIndex of resetIndices) {
                console.log(prev[resetIndex], prev[pickIndex])
                if (prev[resetIndex] === prev[pickIndex]) {
                  newPicks[resetIndex] = undefined
                }
              }
            }
            return newPicks
          }
      )
    }
  }

  return <div className={`championshipBet__pairingRow`}>
    <div className={classNames.join(" ")} onClick={pickWinner}>
      <div className="livTeam__logo" />
      {(!isOpen && isScored && isWinner) && <div className="correct">{I18n.t("championshipbet.ui.select_winner.winner")}</div>}
      {(!isOpen && isScored && picked && !isWinner) && <div className="wrong">{I18n.t("championshipbet.ui.select_winner.pick")}</div>}
    </div>
  </div>
}
