import React, { useState } from 'react'
import I18n from 'i18n-js'
import MediaQuery from 'react-responsive'
import axios from "axios"
import { useDevice } from "@root/misc/device"

import Button from './Button'
import cssVar from '@styles/_export'
import { displayPoints } from "@root/helpers/number_helpers"

const Wrapper = ({ children }) => { return <div className='accountSection'>{children}</div> }
const Head = ({ children }) => { return <div className='accountSection__head'>{children}</div> }
const Body = ({ children }) => { return <div className='accountSection__body buttonGrouper--onDarkBackdrop'>{children}</div> }
const Stats = ({ children }) => { return <div className='accountSection__stats'>{children}</div> }
const AvatarImage = (_) => { return <div className='accountSection__avatarPlaceholder' /> }
const Title = ({ children, big }) => {
  const variantClass = big ? 'accountSection__title--big' : ''
  return (
    <div className={'accountSection__title ' + variantClass}>{children}</div>
  )
}
const SubTitle = ({ onClick, children, icon }) => {
  return <div style={onClick && { cursor: 'pointer' }} onClick={onClick} className={`accountSection__subTitle ${icon && `--icon__${icon}`}`}><span>{children}</span></div>
}
const StatTabs = ({ activeStat, setActiveStat }) => {
  return (
    <ul className='accountSection__statTabs'>
      <li className={'accountSection__statTabs__item' + (activeStat === STAT_OVERALL ? ' --active' : '')} key='overall' onClick={() => { setActiveStat(STAT_OVERALL) }}>{I18n.t('account_section.stat.overall')}</li>
      <li className={'accountSection__statTabs__item' + (activeStat === STAT_CURRENT ? ' --active' : '')} key='current' onClick={() => { setActiveStat(STAT_CURRENT) }}>{I18n.t('account_section.stat.current')}</li>
    </ul>
  )
}
const StatVals = ({ points, rank, hideRank }) => {
  return (
    <div className='accountSection__statVals'>
      <div key='points'>
        <strong>{displayPoints(points || 0)}</strong>
        <span>{I18n.t('general.points')}</span>
      </div>
      {rank && !hideRank
        ? <div key='rank'>
          <strong>{I18n.t('general.rank_with_rank_character', { rank: rank || 0 })}</strong>
          <span>{I18n.t('general.rank')}</span>
        </div>
        : null}
    </div>
  )
}
const STAT_OVERALL = 0
const STAT_CURRENT = 1


/*
  Main Account Box, when logged in (exported as default)
  ┌─────────┐
  │    :)   │
  │  SONIC  │
  ├─────────┤
  │ 99P  #3 │
  └─────────┘
*/
const AccountSection = ({ profile, auth_endpoints: authEndpoints, justOneMatchday, hideOnMobile }) => {
  const { isPhone } = useDevice()

  if (hideOnMobile && isPhone) return <></> // hide if mobile and should be hidden on mobile devices

  const [activeStat, setActiveStat] = useState(STAT_OVERALL)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const activeStatVals = activeStat === STAT_OVERALL ? profile.overall : profile.current_round

  const handleLogout = e => {
    e.preventDefault()
    //authEndpoints.logout_path
    __Neo.openViaPOST(authEndpoints.logout_path)
  }

  return (
    <Wrapper>
      <Head>
        {/* <AvatarImage /> */}
        <div className="accountSection__head__texts">
          <Title big>{profile.nickname}</Title>
          <SubTitle onClick={() => { setSettingsOpen(!settingsOpen) }} icon={settingsOpen ? "settingsOpen" : "settingsClosed"}>
            {I18n.t('account_section.settings')}
          </SubTitle>
        </div>
      </Head>
      {settingsOpen &&
        <div className='accountSection__settings'>
          <a href={authEndpoints.srf_profile_path}>{I18n.t("account_section.srf_profile_link")}</a>
          <a href={authEndpoints.profile_settings_path}>{I18n.t("account_section.edit_profile")}</a>
          <a href="#" onClick={handleLogout}>{I18n.t('account_section.logout')}</a>
        </div>}
      <Stats>
        {!justOneMatchday && <StatTabs activeStat={activeStat} setActiveStat={setActiveStat} />}
        <StatVals {...activeStatVals} hideRank={!!profile?.excluded_from_ranking} />
      </Stats>
    </Wrapper>
  )
}

/*
  Main Account Section, when NOT logged in
  ┌─────────┐
  │    :)   │
  │ WELCOME │
  ├─────────┤
  │ Play for│
  │   free! │
  └─────────┘
*/
const NotLoggedIn = ({ auth_endpoints: authEndpoints, game_over: gameOver, hideOnMobile }) => {
  const { isPhone } = useDevice()

  if (hideOnMobile && isPhone) return <></> // hide if mobile and should be hidden on mobile devices

  return (
    <Wrapper>
      <Head>
        <AvatarImage />
        <div className="accountSection__head__texts">
          <Title>{I18n.t('account_section.not_logged_in.title')}</Title>
          <SubTitle>{I18n.t('account_section.not_logged_in.subtitle')}</SubTitle>
        </div>
      </Head>
      <Body>
        <p>
          <Button primary forceGreen href={authEndpoints.login_path} target={authEndpoints.login_target}>{I18n.t('account_section.two_buttons.login')}</Button>
        </p>
        <p>
          <Button primary href={authEndpoints.register_path} target={authEndpoints.register_target}>{I18n.t('account_section.two_buttons.register')}</Button>
        </p>
      </Body>
    </Wrapper>
  )
}

/*
  Save-Demo-Tipps, when NOT logged in on "demo predictions"-page
  ┌────────────┐
  │     :)     │
  │ Try it out!│
  ├────────────┤
  │ Register to│
  │ save bets! │
  └────────────┘
*/
const SaveDemoBets = ({ auth_endpoints: authEndpoints, hideOnMobile }) => {
  const { isPhone } = useDevice()

  if (hideOnMobile && isPhone) return <></> // hide if mobile and should be hidden on mobile devices

  return (
    <MediaQuery query={`(max-width: ${parseInt(cssVar.breakpointTabletUp) - 0.4}px)`}>
      {(matches) => {
        if (matches) {
          // ------------------------ PHONE ------------------------
          return (
            <Wrapper>
              <Body>
                <p className='accountSection__tagline'>{I18n.t('account_section.demo.tagline__phone')}</p>
                <p>
                  <Button primary href={authEndpoints.register_path} target={authEndpoints.register_target}>{I18n.t('account_section.demo.btn_save')}</Button>
                </p>
              </Body>
            </Wrapper>
          )
        } else {
          // ------------------------ TABLET & UP ------------------------
          return (
            <Wrapper>
              <Head>
                <AvatarImage />
                <div className="accountSection__head__texts">
                  <Title>{I18n.t('account_section.demo.title')}</Title>
                  <SubTitle>{I18n.t('account_section.demo.subtitle')}</SubTitle>
                </div>
              </Head>
              <Body>
                <p className='accountSection__tagline'>{I18n.t('account_section.demo.tagline')}</p>
                <p>
                  <Button primary href={authEndpoints.register_path} target={authEndpoints.register_target}>{I18n.t('account_section.demo.btn_save')}</Button>
                </p>
              </Body>
            </Wrapper>
          )
        }
      }}
    </MediaQuery>
  )
}

export default AccountSection
export { NotLoggedIn, SaveDemoBets }
