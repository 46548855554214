import React, { useCallback, useState } from "react"

interface Props {
  title: string
  content: string
  collapsedByDefault?: boolean
}

const CollapsibleDetailsSection: React.FC<Props> = ({ title, content, collapsedByDefault }) => {

  const [collapsed, setCollapsed] = useState(collapsedByDefault)

  const toggleCollapse = useCallback(() => {
    setCollapsed((currentState) => !currentState)
  }, [])

  const classes = [
    "details__section",
    "details__section--collapsible",
    "details__section--" + ((collapsed) ? "collapsed" : "open"),
  ].join(" ")

  return  <div className={classes}>
    <div className="layout__contentRow" onClick={toggleCollapse}>
      <div className="pageSubtitle">
        <div className="pageSubtitle__title">{title}</div>
      </div>
    </div>
    {!collapsed && <div dangerouslySetInnerHTML={{ __html: content }} />}
  </div>
}

export default CollapsibleDetailsSection