import React, { useEffect, useState } from "react"

import _ from "lodash"
import axios, { AxiosResponse } from "axios"

interface DayScoring {
  name: string
  score: number
  is_future: boolean
}

interface ScoringSummary {
  days: DayScoring[]
  is_running: boolean
  total: number
}

type Error = { error: string }

interface Props {
  round_id: number
  summary: ScoringSummary
  authenticity_token: string
}

const everyTwoMinutes = 2 * 60 * 1000

const isErrorResponse = (res: any | AxiosResponse<Error>): res is AxiosResponse<Error> => {
  return !!(res as AxiosResponse<Error>)?.data?.error
}

const ScoringSummaryComponent: React.FC<Props> = ({
  round_id: roundId,
  summary,
  authenticity_token
}) => {

  const [{days, total, is_running: isRunning}, setSummary] = useState(summary)

  useEffect(() => {
    console.log("Is running?:", isRunning)
    if (isRunning) {
      console.log("Setting interval", everyTwoMinutes)
      const interval = setInterval(async () => {
        console.log("Polling scoring summary for round", roundId, { authenticity_token })
        try {
          const res = await axios.post<ScoringSummary>(`/round/${roundId}/scoring_summary`, { authenticity_token })
          res.data.is_running = res.data.is_running || res.data.is_running === undefined
          setSummary(res.data)
        } catch (e: any) {
          const res = e.response as AxiosResponse<Error> | undefined
          if (isErrorResponse(res)) {
            console.error(res.status, res.data.error)
          } else {
            console.error(e)
          }
        }
      }, everyTwoMinutes)
      return () => {
        clearInterval(interval);
      }
    }
  }, [isRunning])

  return <div className="scoringSummary">
    <div className="scoringSummary__background">
      {_.map(days, ({name, is_future}) => (<div key={`${name}_background`} className={"scoringSummary__background__block" + ((is_future) ? " scoringSummary__background__block--future" : "")}></div>))}
      <div className="scoringSummary__background__block"></div>
    </div>
    {_.map(days, ({name, score}) => (
      <div key={name} className="scoringSummary__block scoringSummary__block">
        <div className="scoringSummary__eventName">{name}</div>
        <div className="scoringSummary__score">{score}</div>
      </div>
    ))}
    <div className="scoringSummary__block scoringSummary__block">
      <div className="scoringSummary__eventName">Total</div>
      <div className="scoringSummary__score">{total}</div>
    </div>
  </div>
}

export default ScoringSummaryComponent