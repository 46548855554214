import React, { useContext } from 'react'
import I18n from 'i18n-js'

import BetContext from './context/bet'
import betStatusPresenter from '@root/bet_utils/presenter/bet_status'
import postponedPresenter from '@root/bet_utils/presenter/postponed'
import cancelledPresenter from '@root/bet_utils/presenter/cancelled'
import { displayPoints } from '@root/helpers/number_helpers'
import { unwrapSec } from '@root/components/SquadBet/helper'

const BetStatus = ({ cssNamespace = 'betStatus', overAndScored }) => {
  const bet = useContext(BetContext)
  if (cancelledPresenter.isCancelled(bet)) {
    return <span className={`${cssNamespace}__value --cancelled`}>{I18n.t('cancelled.status_text_for_bet_header')}</span>
  }
  if (postponedPresenter.isPostponed(bet)) {
    return <span className={`${cssNamespace}__value --postponed`}>{I18n.t('postponed.status')}</span>
  }
  if (betStatusPresenter.isMatchOpen(bet)) {
    const { d, hh, mm } = unwrapSec(bet.secondsLeft)
    return <span className={`${cssNamespace}__value --open`}>
      {d > 0 && <>
        {d}<span className={`${cssNamespace}__countdownDaysLabel`}>{I18n.t("squadbet.countdown.days")}</span></>}
      {`${hh}:${mm}`}
    </span>
  }
  if (_.isNumber(bet.total_score)) {
    const gainedPoints = bet.total_score !== 0
    return <span className={`${cssNamespace}__value --matchOverAndScored`}>
      {overAndScored ? overAndScored(bet.total_score) : I18n.t('bet.total_score', { points: bet.total_score })}
    </span>
  }
  if (betStatusPresenter.isMatchInProgress(bet)) {
    return <span className={`${cssNamespace}__value --matchInProgress`}>{I18n.t('bet.in_progress')}</span>
  }
  if (betStatusPresenter.isMatchOver(bet)) {
    return <span className={`${cssNamespace}__value --matchOver`}>
      {I18n.t('bet.scoring.event_finished')}
    </span>
  }
  return null
}

export default BetStatus
