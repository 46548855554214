import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import _ from "lodash"

import { disableBodyScroll, enableBodyScroll } from "@root/misc/disableBodyScroll"

import { ChampionshipBetTeam } from "./types"
import { TeamItemInner } from "./ui"
import Popup from "./popup"

export interface PickerEntry<ItemType> {
  item: ItemType
  alreadyInSquad?: Boolean
  sameAsAlreadyInThisSlot?: Boolean
  cantPickReason?: string
}

type PickerFn<ItemType> = (entries: PickerEntry<ItemType>[], onPick: (item?: ItemType) => void, onAbort?: () => void) => void

const PickerContext = createContext<{pickTeam: PickerFn<ChampionshipBetTeam>}>({ pickTeam: () => {} })

type PickerPayload = {
  type: string
  entries: PickerEntry<ChampionshipBetTeam>[]
  onPick(item?: ChampionshipBetTeam): void
  onAbort?(): void
}

export const WithPicker: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [payload, setPayload] = useState<PickerPayload>()

  useEffect(() => {
    if (!!payload) {
      disableBodyScroll()
    } else {
      enableBodyScroll()
    }
  }, [!!payload])
  const closePicker = () => setPayload(undefined)

  useEffect(() => { // make sure body can scroll when picker unmounted (e.g. when countdown runs out and bet closes itself)
    return () => { // cleanup
      enableBodyScroll()
    }
  }, [])

  return <PickerContext.Provider value={{
    pickTeam: (entries, onPick, onAbort) => {
      setPayload({type: "team", entries, onPick, onAbort})
    }
  }}>
    <Popup isOpen={!!payload} closePopup={closePicker}>
      {payload && payload.type === "team" && _.map(payload.entries, (entry) => {
        // ------ TEAM ------
        return <div key={entry.item.id} className={`squadBet__picker__item squadBet__picker__item--team ${entry.alreadyInSquad ? "--already" : ""}`}>
          <a className="squadBet__picker__item__inner" onClick={() => {payload.onPick(entry.item); closePicker()}}>
            <div className="squadBet__picker__item__imgHolder">
              <TeamItemInner team={entry.item} />
            </div>
            <div className="squadBet__picker__item__txtHolder">
              <h3>{entry.item.name}</h3>
            </div>
          </a>
        </div>}
      )}
    </Popup>
    {children}
  </PickerContext.Provider>
}

const usePicker = () => useContext(PickerContext)

export default usePicker
