import moment from 'moment'
import _ from "lodash"
import { BaseBet, WithSecondsLeft } from '@root/components/SquadBet/types'
import I18n from 'i18n-js'

const isValidResult = (res: any) => {
  return res.length > 0
}

const betPresenter = {
  isMatchOverAndScored: (bet: WithSecondsLeft<BaseBet>) => {
    return bet.event_state === 'over' &&
      (
          (!!((bet as any).final_results) && isValidResult((bet as any).final_results))
          || (bet as any).scoring_detail
      )
  },
  isMatchOver: (bet: WithSecondsLeft<BaseBet>) => {
    return bet.event_over
  },
  isMatchOverAndWaitingForScores: (bet: WithSecondsLeft<BaseBet>) => {
    return bet.event_state === 'progress' && betPresenter.isMatchOver(bet)
  },
  isMatchInProgress: (bet: WithSecondsLeft<BaseBet>) => {
    return bet.event_state === 'progress' ||
      (bet.event_state === 'open' && bet.secondsLeft <= 0)
  },
  questionWaitingForScoring: (bet: WithSecondsLeft<BaseBet>) => {
    return (betPresenter.isMatchOver(bet) || betPresenter.isMatchInProgress(bet))
      && !isValidResult((bet as any).final_results?.[0])
      && !bet.cancelled
      && !bet.postponed
  },
  isMatchOpen: (bet: WithSecondsLeft<BaseBet>) => {
    return bet.event_state === 'open' && bet.secondsLeft > 0
  },
  canEdit: (bet: WithSecondsLeft<BaseBet>) => {
    return betPresenter.isMatchOpen(bet) && !bet.from_other
  },
  isFromOther: (bet: WithSecondsLeft<BaseBet>) => {
    return !!bet.from_other
  },
  isCensored: (bet: WithSecondsLeft<BaseBet>) => {
    return !!bet.censored
  },
  prettyDate: (bet: WithSecondsLeft<BaseBet>, format?: string) => {
    return moment(bet.event_date).format(format || 'D MMM | HH:mm')
  },
  prettyDateOrCancelledOrTBD: (bet: WithSecondsLeft<BaseBet>, format?: string) => {
    if (bet.postponed) { return I18n.t("squadbet.date_postponed") }
    if (bet.cancelled) { return I18n.t("squadbet.date_cancelled") }
    return betPresenter.prettyDate(bet, format)
  },
  isScoringFinal: (bet: WithSecondsLeft<BaseBet>) => {
    return betPresenter.isMatchOverAndScored(bet) && !bet.temp_scored
  },
  receivedPoints: (bet: WithSecondsLeft<BaseBet>) => {
    return betPresenter.isMatchOverAndScored(bet) && bet.total_score && (bet.total_score > 0)
  }
}

export default betPresenter
