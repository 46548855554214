import React from "react"

interface Props {
  title: string
  message: string
}

const NeoInfoOverlay: React.FC<Props> = ({title, message}) => {
  const searchParams = new URLSearchParams(window.location.search)
  if (searchParams.get("regSuccess") == "true") {
    searchParams.delete("regSuccess")
  }
  return <>
    <div className="neo__infoOverlay" onClick={() => window.location.search = searchParams.toString()}>
      <div className="neo__infoOverlay__box">
        <div className="neo__infoOverlay__title">
          {title}
        </div>
        <div className="neo__infoOverlay__content">
          {message}
        </div>
      </div>
    </div>
  </>
}

export default NeoInfoOverlay