import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import _ from "lodash"

// @ts-ignore
import { topNavigationClick } from '@root/tracking/signals'
import { useDevice } from '@root/misc/device'

const isTouchDevice = () => {  
  return (('ontouchstart' in window) ||  
    (navigator.maxTouchPoints > 0) ||  
    (((navigator as any).msMaxTouchPoints || 0)> 0));  
}  

type MobileMode = "scroll" | "burger"
const MOBILE_MODE: MobileMode = isTouchDevice() ? "scroll" : "burger"

const Navi: React.FC<{items: {url: string, name: string, active?: boolean, target?: string}[]}> = ({items}) => {
  const { isPhone } = useDevice()
  const [ mobileOpened, setMobileOpened ] = useState(false)
  const [ isTooWideForScreen, setIsTooWideForScreen ] = useState(false)
  const initiallyActiveItem = useRef<HTMLElement>()
  const naviList = useRef<HTMLElement>()

  useLayoutEffect(() => {
    const width = naviList.current?.clientWidth
    if (width) {
      setIsTooWideForScreen(width > window.innerWidth)
    }
  })

  useEffect(() => { // phone and scroll? => scroll to active ref
    if (isPhone && (MOBILE_MODE === "scroll") && initiallyActiveItem.current && naviList.current) {
      if (naviList.current.scrollLeft !== 0) { return } // already scrolled, maybe through reload
      naviList.current.scrollLeft = Math.max(initiallyActiveItem.current.getBoundingClientRect().x - 20, 0)
    }
  }, [])

  const className = [
    "navigation__holder",
    isPhone ? "navigation__holder--mobile" : "",    
    isPhone
      ? (MOBILE_MODE === "scroll"
          ? "navigation__holder--mobileScroll"
          : (mobileOpened
            ? "navigation__holder--mobileBurgerOpened"
            : "navigation__holder--mobileBurgerClosed"))
      : ""
  ].join(" ")
  return <div className={className}>
    <ul className="navigation__list" ref={(el: any) => {if (el) {naviList.current = el}}}>
      {_.map(items,(item) => {
        const targetAttr = item.target ? { target: item.target } : {}

        const itemClass = [
          "navigation__item",
          item.active ? "navigation__item--selected" : "navigation__item--unselected"
        ].join(" ")

        const refAttribs = item.active ? {ref: (el: any) => {if(el) { initiallyActiveItem.current = el}}} : {}
        return <li key={item.name} className={itemClass} {...refAttribs}>
          <a className="navigation__link" href={item.url} {...topNavigationClick.trackingProps({ name: item.name })} {...targetAttr}>
            <span>{item.name}</span>
          </a>
        </li>
      })}
    </ul>
    {isPhone && isTooWideForScreen && MOBILE_MODE === "burger" && (
      mobileOpened
      ? <div className='navigation__mobileCloseBurger' onClick={() => setMobileOpened(false)}>
          <span className='icon icon--close--rb-white' />
        </div>
      : <div className='navigation__mobileOpenBurger' onClick={() => setMobileOpened(true)}>
          <span className='icon icon--burger-menu' />
        </div>)}
    {isPhone && MOBILE_MODE === "scroll" &&
      <div className='navigation__mobileScrollFade' />}
  </div>
}

export default Navi
