import React, { useEffect, useMemo, useRef } from "react"

const splitNameAndDates = (nameString: string) => {
  const groups = nameString.match(/((?<name>.+) \((?<dates>.*)\))|(?<name>.+)/)?.groups
  return groups?.name && groups || {}
}

interface Option {
  name: string
  url: string
  selected: boolean
  cancelled: boolean | null
}

const CarouselItem: React.FC<{option: Option}> = ({option}) => {
  const itemClasses = [
    "carousel__item",
    option.selected && "carousel__item--selected",
    option.cancelled && "carousel__item--canceled"
  ].join(" ")
  const {name, dates} = splitNameAndDates(option.name)

  return <>
    <div
      className={itemClasses}
      onClick={() => {window.location.href = option.url}}
    >
      <div>{name}</div>
      {dates && <div>{dates}</div>}
    </div>
  </>
}

interface Props {
  options: Option[]
}

const Carousel: React.FC<Props> = ({ options }) => {

  const scrollContainer = useRef<HTMLDivElement>(null)

  const selectedIndex = useMemo(() => options.findIndex((option) => option.selected), [])

  useEffect(() => {
    const selectedItem = scrollContainer.current?.childNodes[0].childNodes[1 + selectedIndex] as HTMLDivElement | undefined
    if (selectedItem) {
      const offset = selectedItem.offsetLeft
      const itemWidth = selectedItem.clientWidth
      const scrollWidth = scrollContainer.current?.clientWidth
      if (scrollWidth) {
        const things = {left: offset - scrollWidth/2 + itemWidth/2}
        scrollContainer.current?.scroll(things)
      }
    }
  }, [scrollContainer, options])

  return <div className="carousel">
    <div className="carousel__scrollContainer" ref={scrollContainer}>
      <div className="carousel__itemContainer" style={{}}>
        <div className="carousel__itemContainer__arrowSpaceDummy" />
        {
          options.map((option, i) => {
            return <CarouselItem key={`${option.name}${i}`} option={option} />
          })
        }
        <div className="carousel__itemContainer__arrowSpaceDummy" />
      </div>
    </div>
    {(selectedIndex === 0)
      ? <div
          className="carousel__arrowLeft carousel__arrowLeft--inactive"
        />
      : <div
          className={"carousel__arrowLeft"}
          onClick={() => window.location.href = options[selectedIndex - 1].url}
        />
    }
    {(selectedIndex === options.length - 1)
      ? <div
          className="carousel__arrowRight carousel__arrowRight--inactive"
        />
      : <div
          className={"carousel__arrowRight"}
          onClick={() => window.location.href = options[selectedIndex + 1].url}
        />
    }
  </div>
}

export default Carousel