/*
  Sync structure with rails "button" partial!
*/

import React, { PropsWithChildren } from 'react'

interface Props {
  primary?: boolean
  rounded?: boolean
  disabled?: boolean
  forceWhite?: boolean
  forceRed?: boolean
  forceGreen?: boolean
  forceSpecial?: boolean
  noMinWidthOnMobile?: boolean
  className?: string
  href?: string
  onClick?: () => void
  title?: string
  target?: string
  method?: string
}

const Button: React.FC<PropsWithChildren<Props>> = ({
  className,
  primary,
  rounded,
  disabled,
  href,
  onClick,
  children,
  title,
  target,
  method,
  forceWhite,
  forceRed,
  forceGreen,
  forceSpecial,
  noMinWidthOnMobile,
}) => {
  let variant = 'secondary'
  if (primary) variant = 'primary'
  if (disabled) variant = 'disabled'

  const methodAttributes = method ? { "data-method": method, rel: "nofollow" } : {}
  const targetAttr = target ? { target: target } : {}
  const classNames = [
    "button",
    `button--${variant}`,
    forceWhite ? "button--forceWhite" : "",
    forceRed ? "button--forceRed" : "",
    forceGreen ? "button--forceGreen" : "",
    forceSpecial ? "button--forceSpecial": "",
    rounded ? "button--rounded" : "",
    noMinWidthOnMobile ? "button--noMinWidthOnMobile" : "",
    className || "",
  ].join(" ")

  if (disabled) {
    return (
      <span className={classNames}>
        <span className='button__title'>
          {title || children}
        </span>
      </span>
    )
  } else {
    return (
      <a className={classNames} href={href} onClick={onClick} {...targetAttr} {...methodAttributes}>
        <span className='button__title'>
          {title || children}
        </span>
      </a>
    )
  }
}

export default Button
